import React from 'react'
import './Quotelist.css'
import Layout from '../../../common/Layout'
import { useDispatch, useSelector } from 'react-redux'
import {
  dispatchQuickQuote,
  dispatchMotorQuote,
  resetQuickQuoteResults,
  selectedPlanAction,
  quickQuoteResult,
  dispatchKycConfig,
  saveApiErrorReponse,
  dispatchProcessFlowMotor,
  apiErrorReponse,
  dispatchResetMotorQuote,
  resetSelectedPlan
} from '../../../../store/actions/userActions'
import { motorQuotationObj } from '../../../../store/reducers/userReducers'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import {
  bajajApiCall,
  digitAPICall,
  FutureGeneralQuickQuote,
  getLibertyAPICall,
  getZunoQuickQuote,
  HDFCCalculatePremium,
  KotakAPICall,
  getMotorQuote,
  handleQuotesData,
  magmaAPICall,
  RelianceAPICall,
  RoyalSundramAPICall,
  shriramGenerateProposal,
  getKycConfig,
  getKycStatus,
  processFlow,
  getPreviousJourneyDetails
} from '../../../common/Homepage/TPApiCall'
import { useEffect } from 'react'
import moment from 'moment'
import { MultiSelect } from 'react-multi-select-component'
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import { PreviousInsurer } from '../../../utility/BankNames'
import EditVehicleDetails from './EditVehicleDetails'
import Loader from '../../../common/Loader'
import { getVehiclePreviousInsurer } from '../../../Services/masterServices'
import { useForm } from 'react-hook-form'
import { sendErrorInfo } from '../../../Services/PosService'
import Header from '../../../common/Header'
import { cancelRunningTask } from 'react-pdf/dist/umd/shared/utils'
import Footer from '../../../common/Footer'
import Shimmer from '../../../Shimmer/Shimmer'
import {
  policyResultArr,
  policyResultArrNew
} from '../../../common/Homepage/MakeModelArr'
import {
  Covers,
  POLICY_STATUS,
  POLICY_TYPE,
  VEHICLE_TYPE,
  addOnsData,
  extractCustomerLeadId,
  insurers,
  kycType,
  odCovers,
  tpCovers
} from '../../../utility/constants'
import store from '../../../../store'
import { parseInt } from 'lodash'
import QuotelistModel from './QuotelistModel'
import { MdMoreHoriz } from 'react-icons/md'
import SharePaymentQuotationModal from '../../../common/Homepage/SharePaymentQuotationModal'

const Quotelist = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm({ mode: 'onBlur' })
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1 },
    reset: reset1
  } = useForm({ mode: 'onBlur' })
  const QuickQouteResult = useSelector(state => state?.root?.QuickQouteResult)
  const apiRequestQQ = useSelector(state => state?.root?.apiRequestQQ)
  const userDetails = useSelector(state => state?.root?.userDetails)
  const processFlowObjMotor = useSelector(
    state => state?.root?.processFlowObjMotor
  )
  console.log(apiRequestQQ, 'Redux Data')
  const ApiErrorArr = useSelector(state => state?.root?.ApiErrorArr)
  const [idvError, setIdvError] = useState(false)
  const [modelIdv, setModelIdv] = useState('')
  const [shareQuoteLinkModal, setShareQuoteLinkModal] = useState(false)
  const toggleShareQuoteLinkModal = () =>
    setShareQuoteLinkModal(!shareQuoteLinkModal)
  const [addOns, setAddOns] = useState([])
  const [range, setRange] = useState(null)
  const [covers, setCovers] = useState([])
  const [panModal, setPanModal] = useState(false)
  const togglePanModal = () => setPanModal(!panModal)
  const [addOnFilter, setAddonFilter] = useState(false)
  const [onMouseLeave, setOnMouseLeave] = useState(true)
  const [changeAddonFilter, setChnageAddonFilter] = useState(false)
  const [additionalCover, setAdditionalCover] = useState(false)
  const [editDetails, setEditDetails] = useState(false)
  const [onMouseLeaveCover, setOnMouseLeaveCover] = useState(true)
  const [changeCoverFilter, setChangeCoverFilter] = useState(false)
  const [additionCoverOpen, setAddditionCoverOpen] = useState(false)
  const [coverDisplay, setCoverDisplay] = useState(false)
  const [addOnDisplay, setaddOnDisplay] = useState(false)
  const [newStructurePremium, setNewStructurePremium] = useState(false)
  const [modalTrue, setModalTrue] = useState(false)
  const [ncbModel, setNcbModel] = useState(false)
  const [minMaxIdv, setMinMaxIdv] = useState(null)
  const [filter, setFilter] = useState(() => {
    const storedFilter = localStorage.getItem('filter')
    return storedFilter
      ? JSON.parse(storedFilter)
      : {
          roadSideAssistance: false,
          engineProtection: false,
          tyreProtection: false,
          rimProtection: false,
          consumables: false,
          isElectricalAccessories: false,
          isNonElectricalAccessories: false,
          isTppdDiscount: false,
          personalAccident: false,
          invoiceCover: false,
          engineGearBox: false,
          ncbProtection: false,
          isVoluntaryDeductable: false,
          isPassengerCover: false,
          lossOfPersonalBelongings: false,
          zeroDepriciationCover: false,
          keyReplacement: false,
          liabilitiesToPaidDriver: false,
          electricAmount: 0,
          nonElectricAmount: 0,
          passengerCoverAmount: 0,
          voluntaryDeductableAmount: 0,
          noOfPaidDrivers: 0,
          cngValue: 0
        }
  })
  console.log('filternfkdjbnvjksbnd', filter)
  const [NewPolicyType, SetNewPolicyType] = useState('')
  const [prevoisNCB, setPrevoiusNCB] = useState(0)
  const [idv, setIdv] = useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [modal, setModalEdit] = useState(false)

  const [initialCall, setInitialCall] = useState(false)
  const [dataMotorQuotation, setDataMotorQuotation] = useState({})
  const [getMotorQuoteResponse, setGetMotorQuoteResponse] = useState(null)

  const [kycConfigData, setKycConfigData] = useState(false)
  const [kycStatus, setKycStatus] = useState(false)

  const motorRequest = useSelector(state => state?.root?.motorRequest)

  const [rId, setrId] = useState(null)
  const [motorData, setMotorData] = useState(null)

  const [insurerData, setInsurerData] = React.useState([])

  const [hidebtn, setHideBtn] = useState(false)

  const [loading, setLoading] = useState(
    QuickQouteResult?.length > 0 ? false : true
  )

  // const toggleModal = () => setModal(!modal);
  const [selectDoc, setSelectDoc] = useState('')
  const [pan, setPan] = useState({
    number: '',
    image: '',
    error: ''
  })
  const [adhar, setAdhar] = useState({
    number: '',
    image: '',
    error: ''
  })

  const [isRTIChecked1, setIsRTIChecked1] = useState(false)
  const [isRTIChecked2, setIsRTIChecked2] = useState(false)

  const [isZeroDepChecked1, setIsZeroDepChecked1] = useState(false)
  const [isZeroDepChecked2, setIsZeroDepChecked2] = useState(false)

  const [isPAChecked1, setIsPAChecked1] = useState(false)
  const [isPAChecked2, setIsPAChecked2] = useState(false)

  const [validationError, setValidationError] = useState('')

  const [modalOpen2, setModalOpen2] = useState(false)
  const [itemState, setItemState] = useState(null)
  const [itemState3, setItemState3] = useState(null)
  const [modalOpen3, setModalOpen3] = useState(false)
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false
  })

  const [magmaImg, setMagmaImg] = useState(null)

  console.log('magmaImg', magmaImg)

  const [spinner, setSpinner] = useState(false)
  const [showBox, setShowBox] = useState(false)

  const [modal1, setModal1] = useState(false)
  const toggleModal1 = () => setModal1(!modal1)

  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const toggleModal2 = () => setModal2(!modal2)
  const toggleModal3 = () => setModal3(!modal3)
  const [planDetail, setPlanDetail] = useState(null)
  const [planDetail3, setPlanDetail3] = useState(null)

  const [ODModal, setODModal] = useState(false)
  const toggleODModal = () => setODModal(!ODModal)

  const [idvModal, setIdvModal] = useState(false)
  const toggleIdvModal = () => setIdvModal(!idvModal)

  const handleRTIChange1 = () => {
    setIsRTIChecked1(!isRTIChecked1)
    setIsRTIChecked2(false) // Uncheck checkbox 2
  }

  const handleRTIChange2 = () => {
    setIsRTIChecked2(!isRTIChecked2)
    setIsRTIChecked1(false) // Uncheck checkbox 1
  }

  const handleZeroDepChange1 = () => {
    setIsZeroDepChecked1(!isZeroDepChecked1)
    setIsZeroDepChecked2(false) // Uncheck checkbox 2
  }

  const handleZeroDepChange2 = () => {
    setIsZeroDepChecked2(!isZeroDepChecked2)
    setIsZeroDepChecked1(false) // Uncheck checkbox 1
  }

  const handlePAChange1 = () => {
    setIsPAChecked1(!isPAChecked1)
    setIsPAChecked2(false) // Uncheck checkbox 2
  }

  const handlePAChange2 = () => {
    setIsPAChecked2(!isPAChecked2)
    setIsPAChecked1(false) // Uncheck checkbox 1
  }

  const handleCheckboxChange = event => {
    const { name, checked } = event.target
    const updatedCheckboxes = { ...checkboxes }

    // Uncheck all checkboxes except the one clicked
    Object.keys(updatedCheckboxes).forEach(key => {
      if (key !== name) {
        updatedCheckboxes[key] = false
      }
    })

    // Toggle the checkbox that was clicked
    updatedCheckboxes[name] = !updatedCheckboxes[name]

    setCheckboxes(updatedCheckboxes)
  }

  useEffect(() => {
    ;(!motorRequest?.handlePreviousButton || QuickQouteResult.length === 0) &&
      fetchPreviousJourneyDetails()
  }, [])

  useEffect(() => {
    QuickQouteResult.sort((a, b) => a.idv - b.idv)
    if (
      motorRequest?.vehicleType &&
      QuickQouteResult.length > 0 &&
      !motorRequest?.handlePreviousButton
    ) {
      dispatchProcessFlowMotor('step', 'step7')

      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        step: 'step7',
        processDiscription: 'quotation',
        step7: {
          ...processFlowObjMotor.step7,
          motorRequest: { ...motorRequest },
          QuickQouteResult: { ...QuickQouteResult }
        }
      }
      processFlow(dataForFlow)
    }
  }, [QuickQouteResult])

  useEffect(() => {
    if (loading === true) {
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
    if (apiRequestQQ?.NewPolicyType == 'ODOnly') {
      getVehiclePreviousInsurer().then(response => {
        if (response.status === true) {
          let i = 0
          let data = response.data
          let arr = []
          while (i < data.length) {
            let item = data[i]
            arr.push({
              option: item.Name,
              value: item.Digit_Code,
              insurerId: item?.prevInsurerId
            })
            i++
          }
          setInsurerData(arr)
        }
      })
    }
  }, [loading])

  useEffect(() => {
    if (
      motorRequest?.isVehicleNew === false &&
      motorRequest?.vehicleType &&
      motorRequest?.newPolicyType !== POLICY_TYPE?.THIRDPARTY &&
      !motorRequest?.isOwnerChanged &&
      (motorRequest?.businessType === POLICY_STATUS.NOTEXPIRED ||
        motorRequest?.businessType === POLICY_STATUS.EXPIREDWITHIN90)
    ) {
      setNcbModel(true)
    }
  }, [])

  useEffect(() => {
    console.log('Previous NCB', apiRequestQQ)
    setPrevoiusNCB(apiRequestQQ?.PreviousNoClaimBonus)
    let addOns = apiRequestQQ.AddOns
    console.log('addOnsaddOnsaddOns', addOns)
    if (apiRequestQQ.isFiltered === true) {
      let obj = new Object()
      for (let key in addOns) {
        obj[key] = addOns[key]
      }
      setFilter(obj)
    }
  }, [apiRequestQQ])

  const handleChangeFilter = e => {
    const { name, checked } = e.target
    setFilter({ ...filter, [name]: checked })
  }

  useEffect(() => {
    console.log('handleChangeFilter', filter)
  }, [filter])

  const LowestIdv =
    QuickQouteResult.length > 0
      ? QuickQouteResult.map(
          x => x.MinMaxIdv && Math.floor(x.MinMaxIdv.split('-')[0])
        )
      : 0

  console.log('LowestIdv', LowestIdv)

  const val =
    LowestIdv.length > 0 &&
    LowestIdv.sort(function (a, b) {
      return a - b
    })

  console.log('val', val)

  const fetchPreviousJourneyDetails = async () => {
    let newDataForMotorRequest = { ...motorRequest }
    try {
      const urlCustomerLeadId = extractCustomerLeadId(location?.pathname)
      dispatchMotorQuote('customerLeadId', urlCustomerLeadId)

      const response = await getPreviousJourneyDetails({
        customerLeadId: urlCustomerLeadId
      })
      // if (!motorRequest?.journeyFromStart) {
      //   dispatchResetMotorQuote();
      //   dispatch(resetQuickQuoteResults());
      //   dispatch(resetSelectedPlan());
      // }
      console.log('response', response)
      const stepsData = response?.data?.stepsData
      if (
        (response?.data?.processStep === 'step7' &&
          response?.data?.processDiscription === 'quotation') ||
        (response?.data?.processStep === 'step6' &&
          response?.data?.processDiscription === 'pypSelection')
      ) {
        for (let key in stepsData?.step7?.motorRequest) {
          dispatchMotorQuote(`${key}`, stepsData?.step7?.motorRequest[key])
          newDataForMotorRequest[key] = stepsData?.step7?.motorRequest[key]
        }
        // dispatch(resetQuickQuoteResults());
      }

      const updatedFilter = { ...filter }

      Covers.forEach(addon => {
        const motorValue = stepsData?.step7?.motorRequest[addon.value]
        const motorValueAmount = stepsData?.step7?.motorRequest[addon.inputName]
        if (motorValue !== undefined && motorValue !== filter[addon.value]) {
          updatedFilter[addon.value] = motorValue
          if (addon.type === 'input' && addon.inputName) {
            updatedFilter[addon.inputName] = motorValueAmount
          }
        }
      })
      addOnsData.forEach(addon => {
        const motorValue = stepsData?.step7?.motorRequest[addon.value]
        const motorValueAmount = stepsData?.step7?.motorRequest[addon.inputName]
        if (motorValue !== undefined && motorValue !== filter[addon.value]) {
          updatedFilter[addon.value] = motorValue
          if (addon.type === 'input' && addon.inputName) {
            updatedFilter[addon.inputName] = motorValueAmount
          }
        }
      })

      setFilter(updatedFilter)
      localStorage.setItem('filter', JSON.stringify(updatedFilter))

      for (let key in stepsData?.step7?.previousClaimData) {
        dispatchMotorQuote(`${key}`, stepsData?.step7?.previousClaimData[key])
        newDataForMotorRequest[key] = stepsData?.step7?.previousClaimData[key]
      }

      if (
        (motorRequest?.businessType === POLICY_STATUS.NOTEXPIRED ||
          motorRequest?.businessType === POLICY_STATUS.EXPIREDWITHIN90) &&
        !motorRequest?.isVehicleNew &&
        stepsData?.step6?.pypSelection?.newPolicyType !==
          POLICY_TYPE.THIRDPARTY &&
        !stepsData?.step5?.isOwnerChanged &&
        stepsData?.step6?.pypSelection?.businessType !==
          POLICY_STATUS.NOTEXPIRED &&
        motorRequest?.businessType !== 'new' &&
        !motorRequest?.isVehicleNew
      ) {
        setNcbModel(true)
      }

      if (
        motorRequest?.businessType !== POLICY_STATUS.NOTEXPIRED ||
        motorRequest?.newPolicyType === POLICY_TYPE?.THIRDPARTY ||
        motorRequest?.isOwnerChanged
      ) {
        newDataForMotorRequest && motorQuotation(newDataForMotorRequest)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (motorRequest?.vehicleType && QuickQouteResult.length > 0) {
      dispatchProcessFlowMotor('step', 'step7')
      const newData = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        step: 'step7',
        motorRequest: { ...motorRequest },
        processDiscription: 'quotation',
        step7: {
          ...processFlowObjMotor.step7,
          motorRequest: { ...motorRequest },
          QuickQouteResult: { ...QuickQouteResult }
        }
      }

      // if (!cookies.customerLeadId) {
      //   const now = new Date();
      //   // Set the cookie to expire in 2 hours
      //   const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
      //   setCookie("customerLeadId", motorRequest?.customerLeadId, { expires });
      // }
      processFlow(newData)
    }

    QuickQouteResult &&
      QuickQouteResult.map(quote => {
        if (
          quote?.Api_name ===
            'Future Generali India Insurance Company Limited' &&
          quote?.isAddonQuesAsk
        ) {
          setAddonsQuesAsk(quote?.addonQuesArray)
        }
      })
  }, [QuickQouteResult])

  const getMinMaxIdv = arr => {
    let minimumIdv = Math.pow(10, 1000)
    let maximumIdv = -1

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].MinIdv < minimumIdv) {
        minimumIdv = Math.ceil(arr[i].MinIdv)
      }
      if (arr[i].MaxIdv > maximumIdv) {
        maximumIdv = Math.floor(arr[i].MaxIdv)
      }
    }
    return { minimumIdv, maximumIdv }
  }

  const handleChangeValue = e => {
    const { name, value } = e.target
    setFilter({ ...filter, [name]: parseFloat(value) })
  }

  const handleFilterIDV = data => {
    console.log('handleFilterIDV', data)
    const idv = data?.idv
    // console.log(" idvvvdvvdvdv", idv);
    // const newData = { ...apiRequestQQ, Idv: idv };
    // const newData2 = { ...motorRequest, idv: idv };
    // handleFilteredInsurer(newData, newData2);
    const minValue = Math.round(minMaxIdv?.minimumIdv)
    const maxValue = Math.round(minMaxIdv?.maximumIdv)
    const numericValue = parseInt(idv, 10)
    console.log('numericValue', numericValue)

    if (numericValue >= minValue && numericValue <= maxValue) {
      dispatchMotorQuote('idv', parseInt(idv))

      dispatch(resetQuickQuoteResults())

      let newStructureData = { ...motorRequest, idv: numericValue }

      if (numericValue > 0) {
        newStructureData = {
          ...newStructureData,
          sessionId:
            getMotorQuoteResponse?.sessionId || newStructureData?.sessionId
        }
        getMotorQuoteResponse &&
          dispatchMotorQuote('sessionId', getMotorQuoteResponse?.sessionId)
      } else {
        if (newStructureData) {
          newStructureData = {
            ...newStructureData,
            sessionId: ''
          }
          dispatchMotorQuote('sessionId', '')
        }
      }

      motorQuotation(newStructureData)
      toggleIdvModal()
    }
  }

  const handleChangeNCB = item => {
    setPrevoiusNCB(item)

    let newData = { ...motorRequest }

    if (item && motorRequest.businessType !== POLICY_STATUS.NEW) {
      newData['prevNoClaimBonus'] = item
      dispatchMotorQuote('prevNoClaimBonus', item)
    } else {
      dispatchMotorQuote('prevNoClaimBonus', 0)
    }
    console.log('NEW DATA NCB', newData)
    handleFilteredInsurer(newData)
  }

  // const handleChangeIdvFilter = e => {
  //   const { value } = e.target
  //   setIdv(value)
  // }

  useEffect(() => {
    console.log('cover filter', filter)
  }, [filter])

  const handleChangeIdvFilter = e => {
    const { value } = e.target
    setIdv(value)
  }

  const checkFilter = arr => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] == true) {
        return true
      }
    }
    return false
  }

  const getPreviousPolicyName = () => {
    let index = PreviousInsurer?.findIndex(
      item => item?.Digit_Code == apiRequestQQ?.PreInsurerCode
    )
    if (index > -1) {
      return PreviousInsurer[index].Name
    } else {
      return 'N/A'
    }
  }

  const selectUserPlan = item => {
    // dispatch(resetQuickQuoteResults());
    console.log('NEW policy id details', item)
    const finalAmount =
      typeof item?.FinalPremium === 'number'
        ? `${Math.round(item?.FinalPremium.toString().replace('INR', ''))}`
        : `${typeof item?.FinalPremium === 'string'}`
        ? `${Math.round(item?.FinalPremium.replace('INR', ''))}`
        : `${Math.round(item?.FinalPremium)}`
    dispatch(selectedPlanAction({ ...item, FinalPremium: finalAmount }))
    dispatchQuickQuote('ApiId', item?.ApiId)
    dispatchQuickQuote('PolicyId', item?.PolicyId)
    dispatchQuickQuote('PaymentId', item?.ApiId)
    if (item?.Api_name == 'digit') {
      // dispatchQuickQuote("Idv", item?.idv);
    }
    if (item.Api_name === 'Kotak') {
      // if (apiRequestQQ.NewPolicyType == 'ODOnly') {
      //   return toggleODModal()
      // } else {
      dispatchQuickQuote('ApiId', item?.ApiId)
      dispatchQuickQuote('PaymentId', item?.PolicyId)
      // }
    }
    if (item?.Api_name === 'Future') {
      dispatchQuickQuote('AddOns.' + 'discount', item?.discount)
      // dispatchQuickQuote("Idv", item?.idv);
    } else if (item?.Api_name === 'Royal') {
      // dispatchQuickQuote("Idv", item?.idv);
      dispatchQuickQuote('ApiId', item?.ApiId)
      dispatchQuickQuote('PaymentAmount', item?.FinalPremium)
    }
    dispatchQuickQuote('PolicyStartDate', item?.StartDate)
    dispatchQuickQuote('PolicyEndDate', item?.EndDate)
    dispatchQuickQuote('Idv', parseInt(item?.idv))
    // if (
    //   selectUserPlan.Api_name === "Tata AIG General Insurance Co. Ltd." ||
    //   selectUserPlan.Api_name === "ICICI Lombard General Insurance Co. Ltd." ||
    //   selectUserPlan.Api_name === "Bajaj Allianz General Insurance Co. Ltd." ||
    //   selectUserPlan.Api_name === "HDFC" ||
    //   selectUserPlan.Api_name ===
    //     "Zurich Kotak General Insurance Company (India) Limited" ||
    //   selectUserPlan.Api_name ===
    //     "Future Generali India Insurance Company Limited"
    // ) {
    //   navigate(`/proposal/${motorRequest?.customerLeadId}`);
    // } else {
    //   navigate("/proposal");
    // }
    navigate(`/proposal/${motorRequest?.customerLeadId}`)
  }
  const handleUploadPanFile = async file => {
    const base64Img = await toBase64(file[0])
    setPan({ ...pan, image: base64Img })
  }
  const handleChangePanValue = value => {
    setPan({ ...pan, number: value, error: '' })
  }

  const handleUploadAdharFile = async file => {
    const base64Img = await toBase64(file[0])
    setAdhar({ ...adhar, image: base64Img })
  }
  const handleChangeAdharValue = value => {
    setAdhar({ ...adhar, number: value, error: '' })
  }

  const magmaPaYesCall = () => {
    setShowBox(false)
    setCheckboxes({
      checkbox1: false,
      checkbox2: false,
      checkbox3: false
    })
    dispatchQuickQuote('AddOns.PersonalAccident', true)
    //  QuickQouteResult.map((item) =>setPlanDetail(item) )
  }

  const magmaPaNoCall = () => {
    setShowBox(!showBox)
    setIsPAChecked1(false)
    setIsPAChecked2(false)
  }

  const magmaSubmitCall = () => {
    // setHideBtn(true);
    // setSpinner(true);
    if (
      !motorRequest.isMotorRequestTrue &&
      apiRequestQQ.NewPolicyType !== 'ODOnly' &&
      !isPAChecked1 &&
      !checkboxes.checkbox1 &&
      !checkboxes.checkbox2 &&
      !checkboxes.checkbox3
    ) {
      setSpinner(false)
      setHideBtn(false)
      setValidationError(
        'Please select at least one checkbox from PA Owner Cover'
      )

      return
    }

    if (
      apiRequestQQ.NewPolicyType !== 'Bundled'
        ? apiRequestQQ.AddOns.ZeroDepriciationCover === true &&
          !isZeroDepChecked1 &&
          !isZeroDepChecked2
        : ''
    ) {
      setSpinner(false)
      setHideBtn(false)
      setValidationError('Please select at least one checkbox from ZeroDep')
      return
    }

    if (
      apiRequestQQ.NewPolicyType !== 'Bundled'
        ? apiRequestQQ.AddOns.InvoiceCover === true &&
          !isRTIChecked1 &&
          !isRTIChecked2
        : ''
    ) {
      setSpinner(false)
      setHideBtn(false)
      setValidationError('Please select at least one checkbox from RTI Cover')

      return
    }

    if (showBox) {
      setSpinner(true)

      console.log('checkboxes', checkboxes)

      for (let key in checkboxes) {
        console.log(`PAChecBox.${key}`, checkboxes[key])
        dispatchQuickQuote(`PAChecBox.${key}`, checkboxes[key])
      }

      dispatchQuickQuote('AddOns.PersonalAccident', false)

      if (Object.values(checkboxes).some(value => value === true)) {
        console.log('Form submitted successfully!')

        setSpinner(true)

        console.log('checkboxes', checkboxes)

        for (let key in checkboxes) {
          console.log(`PAChecBox.${key}`, checkboxes[key])
          dispatchQuickQuote(`PAChecBox.${key}`, checkboxes[key])
        }

        dispatchQuickQuote('AddOns.PersonalAccident', false)
        dispatchQuickQuote('isPrevInvoiceCover', isRTIChecked1)
        dispatchQuickQuote('isPrevZeroDepreciationCover', isZeroDepChecked1)

        const newObj = {
          ...apiRequestQQ,
          PAChecBox: {
            ...apiRequestQQ.PAChecBox,
            checkbox1: checkboxes.checkbox1,
            checkbox2: checkboxes.checkbox2,
            checkbox3: checkboxes.checkbox3
          },
          AddOns: { ...apiRequestQQ.AddOns, PersonalAccident: false },
          isPrevInvoiceCover: isRTIChecked1,
          isPrevZeroDepreciationCover: isZeroDepChecked1
        }

        if (itemState?.Api_name === 'hdfc') {
          if (apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.hdfc?.min) {
            newObj['Idv'] = apiRequestQQ?.initialIdvData?.hdfc?.min
          } else if (
            apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.hdfc?.max
          ) {
            newObj['Idv'] = apiRequestQQ?.initialIdvData?.hdfc?.max
          }
        } else if (itemState?.Api_name === 'Magma') {
          if (apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.Magma?.min) {
            newObj['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.min
          } else if (
            apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.Magma?.max
          ) {
            newObj['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.max
          }
        }

        dispatchQuickQuote('Idv', parseInt(newObj?.Idv))
        console.log('dsfukg', newObj)

        console.log(QuickQouteResult)
        console.log('itemStateee', itemState)

        // itemState.Api_name === "Magma" &&
        //   magmaAPICall(newObj).then((res) => {
        //     setModalTrue(true);
        //     setHideBtn(false);
        //     setSpinner(false);
        //     setCheckboxes({
        //       checkbox1: false,
        //       checkbox2: false,
        //       checkbox3: false,
        //     });
        //   });

        // itemState.Api_name === "HDFC" &&
        //   HDFCCalculatePremium(newObj).then((res) => {
        //     // setModalOpen2(!modalOpen2);
        //     // toggleModal2();
        //     setModalTrue(true);
        //     setSpinner(false);
        //     setHideBtn(false);
        //     setCheckboxes({
        //       checkbox1: false,
        //       checkbox2: false,
        //       checkbox3: false,
        //     });
        //   });
      } else {
        if (
          !checkboxes.checkbox1 &&
          !checkboxes.checkbox2 &&
          !checkboxes.checkbox3
        ) {
          setSpinner(false)
          setHideBtn(false)
          {
            motorRequest.isMotorRequestTrue === false &&
              apiRequestQQ.NewPolicyType !== 'ODOnly' &&
              setValidationError(
                'Please select at least one checkbox from PA Owner Cover'
              )
          }

          return
        }
        setSpinner(false)
        setHideBtn(false)
      }
    } else {
      // setSpinner(true);
      dispatchQuickQuote('AddOns.PersonalAccident', true)
      dispatchQuickQuote('isPrevInvoiceCover', isRTIChecked1)
      dispatchQuickQuote('isPrevZeroDepreciationCover', isZeroDepChecked1)
      dispatchMotorQuote('isPrevInvoiceCover', isRTIChecked1)
      dispatchMotorQuote('isPrevZeroDepreciationCover', isZeroDepChecked1)

      const newObj = {
        ...apiRequestQQ,
        AddOns: { ...apiRequestQQ.AddOns, PersonalAccident: true },
        isPrevInvoiceCover: isRTIChecked1,
        isPrevZeroDepreciationCover: isZeroDepChecked1
      }

      const newStr = {
        ...motorRequest,
        isPrevInvoiceCover: isRTIChecked1,
        isPrevZeroDepreciationCover: isZeroDepChecked1
      }

      if (itemState?.Api_name === 'hdfc') {
        if (apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.hdfc?.min) {
          newObj['Idv'] = apiRequestQQ?.initialIdvData?.hdfc?.min
        } else if (
          apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.hdfc?.max
        ) {
          newObj['Idv'] = apiRequestQQ?.initialIdvData?.hdfc?.max
        }
      } else if (itemState?.Api_name === 'Magma') {
        if (apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.Magma?.min) {
          newObj['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.min
        } else if (
          apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.Magma?.max
        ) {
          newObj['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.max
        }
      } else if (motorRequest.isMotorRequestTrue) {
        motorQuotation(newStr)
        setSpinner(false)
        !newStructurePremium && setModalOpen2(false)
        setSpinner(false)

        newStructurePremium && toggleModal3()
        // setPlanDetail3(item)
        // setItemState3(item)
      }

      dispatchQuickQuote('Idv', parseInt(newObj?.Idv))

      // itemState?.Api_name === "HDFC" &&
      //   HDFCCalculatePremium(newObj).then((res) => {
      //     setSpinner(true);
      //     setHideBtn(false);
      //     // setModalOpen2(!modalOpen2);

      //     // toggleModal2();
      //     setModalTrue(true);

      //     setSpinner(false);
      //   });

      // itemState.Api_name === "Magma" &&
      //   magmaAPICall(newObj).then((res) => {
      //     setHideBtn(false);
      //     setSpinner(true);
      //     setModalTrue(true);
      //   });
      console.log('itemState', itemState)
    }
    setSpinner(true)
  }

  useEffect(() => {
    console.log('modalTrue', modalTrue)
    if (modalTrue) {
      console.log('QuickQuoteResult', QuickQouteResult)
      QuickQouteResult.map(item => {
        if (item.Api_name === 'Magma' && itemState.Api_name === 'Magma') {
          setPlanDetail(item)
          setMagmaImg(item.Logo)
          setTimeout(() => {
            setModalOpen2(!modalOpen2)
            toggleModal2()
            setSpinner(false)
          }, [1000])
        } else if (item.Api_name === 'hdfc' && itemState.Api_name === 'hdfc') {
          setPlanDetail(item)
          setMagmaImg(item.Logo)
          setTimeout(() => {
            setModalOpen2(!modalOpen2)
            toggleModal2()
            setSpinner(false)
          }, [1000])
        }
      })
      setModalTrue(false)
    }
  }, [QuickQouteResult, modalTrue])

  const submitPanDetails = e => {
    e.preventDefault()

    if (/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(pan?.number) === false) {
      return setPan({ ...pan, error: 'Invalid Pan Number' })
    }
    setLoading(true)
    dispatch(resetQuickQuoteResults())
    dispatchQuickQuote('PanNumber', pan?.number)
    dispatchQuickQuote('PanImage', pan?.image.split(',')[1])
    let newData = { ...apiRequestQQ, userDetails: userDetails }
    newData.PanNumber = pan?.number
    newData.PanImage = pan?.image.split(',')[1]

    // HDFC
    if (
      moment().format('YYYY') - apiRequestQQ?.RegistrationYear <= 15 &&
      apiRequestQQ?.PolicyStatus == 'continue'
    ) {
      // HDFCCalculatePremium(newData);
    }

    // Digit
    // digitAPICall(newData);

    // Bajaj
    if (
      moment().format('YYYY') - apiRequestQQ?.RegistrationYear <= 15 &&
      apiRequestQQ?.PolicyStatus == 'continue'
    ) {
      // bajajApiCall(newData);
    }

    // ShriRam
    // shriramGenerateProposal(newData);

    // Kotak
    if (apiRequestQQ.PreInsurerCode != '152') {
      // KotakAPICall(newData);
    }

    // Reliance
    // if (
    //   moment().format("YYYY") - apiRequestQQ?.RegistrationYear <= 15 &&
    //   apiRequestQQ?.PolicyStatus == "continue"
    // ) {
    // RelianceAPICall(newData);
    // }

    // Future
    // FutureGeneralQuickQuote(newData);

    // Royal Sundaram
    // RoyalSundramAPICall(newData);

    // Zuno
    // getZunoQuickQuote(newData);

    // Liberty
    // getLibertyAPICall(newData);

    //megma

    // magmaAPICall(newData);
    setPanModal()
  }

  // const submitAdharDetails = e => {
  //   e.preventDefault()
  //   if (/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(adhar?.number) === false) {
  //     return setAdhar({ ...adhar, error: 'Invalid Adhar Number' })
  //   }
  //   setLoading(true)
  //   dispatch(resetQuickQuoteResults())
  //   dispatchQuickQuote('AdharNumber', adhar?.number)
  //   dispatchQuickQuote('AdharImage', adhar?.image.split(',')[1])
  //   let newData = { ...apiRequestQQ }
  //   newData.AdharNumber = adhar?.number
  //   newData.AdharImage = adhar?.image.split(',')[1]

  //   // Bajaj
  //   if (
  //     moment().format('YYYY') - apiRequestQQ?.RegistrationYear <= 15 &&
  //     apiRequestQQ?.PolicyStatus == 'continue'
  //   ) {
  //     bajajApiCall(newData)
  //   }

  //   setModal1()
  // }

  const submitAdharPanDetails = () => {
    // submitAdharDetails();
    submitPanDetails()
  }
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  const kotakODOnlyData = data => {
    let newData = { ...apiRequestQQ }
    for (let key in data) {
      newData[key] = data[key]
      dispatchQuickQuote(key, data[key])
    }

    // KotakAPICall(newData).then((response) => {
    //   if (response.vErrorMsg === "Success") {
    //     const index = QuickQouteResult.findIndex(
    //       (item) => item?.Api_name == "Kotak"
    //     );
    //     let item = QuickQouteResult[index];
    //     dispatchQuickQuote("ApiId", response?.vQuoteId);
    //     dispatchQuickQuote("PaymentId", response?.vWorkFlowID);
    //     item.ApiId = response?.vQuoteId;
    //     item.PaymentId = response?.vWorkFlowID;
    //     dispatch(selectedPlanAction(item));
    //     navigate("/proposal");
    //   } else {
    //     return sendErrorInfo("Details not fetched, try again");
    //   }
    // });
  }

  // const getMinMaxIdv = (arr) => {

  //   console.log("arr20", arr);
  //   let minimumIdv = Math.pow(10, 1000);
  //   let maximumIdv = -1;
  //   let minMaxIdvRange; // this will store the values for idv range of min & max

  //   for (let i = 0; i < arr.length; i++) {
  //     minMaxIdvRange = arr[i]?.MinMaxIdv;
  //     const splitedRange = minMaxIdvRange.split(" - "); // we will store the splited range here
  //     console.log("splited Range", splitedRange);
  //     for (let j = 0; j < splitedRange.length; j++) {
  //       if (splitedRange[j] < minimumIdv) {
  //         minimumIdv = Math.ceil(splitedRange[j]);
  //         console.log(minimumIdv, j, "minimum idv");
  //       }
  //       if (splitedRange[j + 1] > maximumIdv) {
  //         maximumIdv = Math.floor(splitedRange[j + 1]);
  //         console.log(maximumIdv, j, "maximum idv");
  //       }
  //     }
  //   }
  //   return { minimumIdv, maximumIdv };
  // };

  // const range = getMinMaxIdv(QuickQouteResult);

  let calculateYearDifference = (date1, date2) => {
    // Convert strings to Date objects if they are not already
    const startDate = new Date(date1)
    const endDate = new Date(date2)

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(startDate - endDate)

    // Convert milliseconds to years (assuming 365.25 days in a year for accuracy)
    const differenceYears = differenceMs / (1000 * 60 * 60 * 24 * 365.25)

    // Round down to the nearest integer to get the whole number of years
    // const yearsDifference = Math.floor(differenceYears);

    console.log('Years Difference', differenceYears)
    return differenceYears
    // setYearDiff[yearsDifference];
  }

  let yearDiff = calculateYearDifference(
    motorRequest.registrationDate,
    new Date().toISOString().slice(0, 10)
  )

  // const handleIinitialIDV = () => {

  //   if (apiRequestQQ?.idvRangeSet === false && QuickQouteResult.length > 0) {
  //     const initialIdvData = {
  //       digit: {
  //         min: "",
  //         max: "",
  //       },
  //       bajaj: {
  //         min: "",
  //         max: "",
  //       },
  //       hdfc: {
  //         min: "",
  //         max: "",
  //       },
  //       Kotak: {
  //         min: "",
  //         max: "",
  //       },
  //       Future: {
  //         min: "",
  //         max: "",
  //       },
  //       Magma: {
  //         min: "",
  //         max: "",
  //       },
  //       Reliance: {
  //         min: "",
  //         max: "",
  //       },
  //       Zuno: {
  //         min: "",
  //         max: "",
  //       },
  //     };

  //     QuickQouteResult.forEach((api) => {

  //       console.log(`${api}`, api);
  //       const { Api_name, MinMaxIdv } = api;
  //       const [minIDV, maxIDV] = MinMaxIdv.split(" - ");
  //       console.log("minIDVminIDV", minIDV, maxIDV, Api_name);
  //       if (Api_name === "digit") {
  //         initialIdvData.digit.min = Math.ceil(minIDV);
  //         initialIdvData.digit.max = Math.floor(maxIDV);
  //       } else if (Api_name === "bajaj") {
  //         initialIdvData.bajaj.min = Math.ceil(minIDV);
  //         initialIdvData.bajaj.max = Math.floor(maxIDV);
  //       } else if (Api_name === "hdfc") {
  //         initialIdvData.hdfc.min = Math.ceil(minIDV);
  //         initialIdvData.hdfc.max = Math.floor(maxIDV);
  //       } else if (Api_name === "Kotak") {
  //         initialIdvData.Kotak.min = Math.ceil(minIDV);
  //         initialIdvData.Kotak.max = Math.floor(maxIDV);
  //       } else if (Api_name === "Future") {
  //         initialIdvData.Future.min = Math.ceil(minIDV);
  //         initialIdvData.Future.max = Math.floor(maxIDV);
  //       } else if (Api_name === "Magma") {
  //         initialIdvData.Magma.min = Math.ceil(minIDV);
  //         initialIdvData.Magma.max = Math.floor(maxIDV);
  //       } else if (Api_name === "Reliance") {
  //         initialIdvData.Reliance.min = Math.ceil(minIDV);
  //         initialIdvData.Reliance.max = Math.floor(maxIDV);
  //       } else if (Api_name === "Zuno") {
  //         initialIdvData.Zuno.min = Math.ceil(minIDV);
  //         initialIdvData.Zuno.max = Math.floor(maxIDV);
  //       }
  //     });
  //     dispatchQuickQuote("initialIdvData", initialIdvData);
  //     console.log("QuickQouteResult", QuickQouteResult);
  //     // const rangee = getMinMaxIdv(QuickQouteResult);
  //     // console.log("rangee", rangee);
  //     // setRange(rangee);
  //     dispatchQuickQuote("idvRangeSet", true);
  //   }
  // };

  useEffect(() => {
    const minMaxRange = getMinMaxIdv(QuickQouteResult)
    setMinMaxIdv(minMaxRange)
  }, [QuickQouteResult])

  useEffect(() => {
    console.log(('range', range))
  }, [range])

  const handleFilterCover = data => {
    console.log('daaaaataaaaaaaa', data)
    const newData = { ...motorRequest, newPolicyType: data }
    dispatchMotorQuote('newPolicyType', data)

    motorQuotation(newData)
  }

  const handleFilteredInsurer = newStructureData => {
    setMotorData(newStructureData)

    // dispatchQuickQuote("isFiltered", true);
    // for (let key in filter) {
    //   console.log(`AddOns.${key}`, filter[key]);
    //   dispatchQuickQuote("AddOns." + key, filter[key]);
    // }
    console.log('RANGE IDN|V', minMaxIdv)

    dispatch(resetQuickQuoteResults())

    newStructureData && motorQuotation(newStructureData)

    // if(motorRequest.idv >= 0){

    //   console.log('newStructureData',newStructureData)
    //   // console.log('keyyyy',key)

    //   motorQuotation(newStructureData)
    // }
  }

  const motorQuotation = async data => {
    dispatch(resetQuickQuoteResults())
    if (data) {
      let response
      let isEqual
      let newData = { ...data }
      for (let key in data) {
        if (data[key] !== dataMotorQuotation[key]) {
          isEqual = false
          break
        } else {
          isEqual = true
        }
      }
      if (!isEqual) {
        dispatchMotorQuote('rId', '')
        newData = { ...newData, rId: '' }
      }
      setDataMotorQuotation(newData)
      let updatedData = { ...newData }
      let executionCount = 0
      const fetchQuotesData = async () => {
        executionCount++
        try {
          if (
            updatedData?.rId &&
            response?.caseStatus === 'Quotes Generated' &&
            response?.isAllQuotesDone
          ) {
            // response = await getMotorQuote(updatedData);
            setGetMotorQuoteResponse(response)
            // if (executionCount >= 5) {
            handleQuotesData(response)
            clearInterval(interval)
            // }
            if (response?.errors.length > 0) {
              if (response?.quotes.length > 0) {
                setGetMotorQuoteResponse(response)
                handleQuotesData(response)
              }

              response?.errors.map(error =>
                store.dispatch(
                  apiErrorReponse(
                    failureCaseArr(
                      error?.insurerName,
                      error?.logo,
                      error?.displayMessage,
                      error?.insurerId
                    )
                  )
                )
              )
              // store.dispatch(apiErrorReponse(failureCaseArr(response.errors)));
              clearInterval(interval)
            }
            // if (response?.quotes?.length > 0) {
            //   if (executionCount >= 12) clearInterval(interval);
            //   console.log("executionCount", executionCount);
            //   response = await getMotorQuote(updatedData);
            //   setGetMotorQuoteResponse(response);
            //   handleQuotesData(response);
            // } else {
            //   response = await getMotorQuote(updatedData);
            // }
          } else if (
            updatedData?.rId &&
            response?.caseStatus === 'Quotes Requested'
          ) {
            response = await getMotorQuote(updatedData)
            setGetMotorQuoteResponse(response)
          } else if (response?.caseStatus === 'Quotes In Process') {
            if (response?.quotes?.length > 0) {
              if (executionCount >= 50) clearInterval(interval)
              response = await getMotorQuote(updatedData)
              setGetMotorQuoteResponse(response)
              handleQuotesData(response)
            } else {
              response = await getMotorQuote(updatedData)
              if (executionCount >= 50) clearInterval(interval)
            }
          } else {
            if (executionCount >= 50) {
              clearInterval(interval)
            } else {
              response = await getMotorQuote(updatedData)
            }
            if (
              response?.status === 'failure' &&
              response?.errors?.length > 0
            ) {
              response?.errors.map(error =>
                store.dispatch(
                  apiErrorReponse(
                    failureCaseArr(
                      error?.insurerName,
                      error?.logo,
                      error?.displayMessage,
                      error?.insurerId
                    )
                  )
                )
              )
              clearInterval(interval)
              throw new Error('API Error occurred')
            }
            setGetMotorQuoteResponse(response)
            updatedData = { ...updatedData, rId: response?.rId }
            dispatchMotorQuote('rId', response?.rId)
          }
        } catch (error) {
          console.error('Error occurred:', error)
          clearInterval(interval)
        }
      }

      const interval = setInterval(fetchQuotesData, 500)
    }
  }

  const compareObjects = (prevObj, newObj) => {
    console.log('Object 1', prevObj)
    console.log('Object 1', newObj)
    for (let key in prevObj) {
      console.log('comparison', prevObj[key], newObj[key])
      if (prevObj[key] !== newObj[key]) {
        return false
      }
    }
    return true
  }

  const handleQuotesData = data => {
    console.log('handleQuotesData', data)
    dispatchMotorQuote('caseId', data?.caseId)
    data?.quotes?.map(item =>
      store.dispatch(
        quickQuoteResult(
          policyResultArrNew(
            item?.insurerId === insurers.Digit.insurerId
              ? insurers.Digit.logo
              : item?.insurerId === insurers.Future.insurerId
              ? insurers.Future.logo
              : item?.insurerId === insurers.Bajaj.insurerId
              ? insurers.Bajaj.logo
              : item?.insurerId === insurers.Kotak.insurerId
              ? insurers.Kotak.logo
              : item?.insurerId === insurers.HDFC.insurerId
              ? insurers.HDFC.logo
              : item?.insurerId === insurers.TATA.insurerId
              ? insurers.TATA.logo
              : item?.insurerId === insurers.ICICI.insurerId
              ? insurers.ICICI.logo
              : null,
            item?.insurer,
            motorRequest?.registrationNumber,
            item?.make,
            item?.model,
            item?.variant,
            item?.basicODPremium,
            item?.basicTPPremium,
            item?.netPremium,
            item?.gst,
            item?.finalPremium,
            item?.minIdv,
            item?.maxIdv,
            item?.idv,
            item?.policyStartDate,
            item?.policyEndDate,
            item?.discount,
            item?.insurer,
            item?.policyType,
            item?.roadSideAssistance?.isOpted,
            item?.roadSideAssistance?.isOpted
              ? item?.roadSideAssistance?.premium
              : item?.roadSideAssistance?.isOpted,
            item?.engineProtection?.isOpted,
            item?.engineProtection?.isOpted
              ? item?.engineProtection?.premium
              : item?.engineProtection?.isOpted,
            item?.tyreProtection?.isOpted,
            item?.tyreProtection?.isOpted
              ? item?.tyreProtection?.premium
              : item?.tyreProtection?.isOpted,
            item?.rimProtection?.isOpted,
            item?.rimProtection?.isOpted
              ? item?.rimProtection?.premium
              : item?.rimProtection?.isOpted,
            item?.consumables?.isOpted,
            item?.consumables?.isOpted
              ? item?.consumables?.premium
              : item?.consumables?.isOpted,
            item?.electricalAccessories?.isOpted,
            item?.electricalAccessories?.isOpted
              ? item?.electricalAccessories?.premium
              : item?.electricalAccessories?.isOpted,
            item?.nonElectricalAccessories?.isOpted,
            item?.nonElectricalAccessories?.isOpted
              ? item?.nonElectricalAccessories?.premium
              : item?.nonElectricalAccessories?.isOpted,
            item?.invoiceCover?.isOpted,
            item?.invoiceCover?.isOpted
              ? item?.invoiceCover?.premium
              : item?.invoiceCover?.isOpted,
            item?.ncbProtection?.isOpted,
            item?.ncbProtection?.isOpted
              ? item?.ncbProtection?.premium
              : item?.ncbProtection?.isOpted,
            item?.voluntaryDeductive?.isOpted,
            item?.voluntaryDeductive?.isOpted
              ? item?.voluntaryDeductive?.premium
              : item?.voluntaryDeductive?.isOpted,
            item?.passengerCover?.isOpted,
            item?.passengerCover?.isOpted
              ? item?.passengerCover?.premium
              : item?.passengerCover?.isOpted,
            item?.paCoverAmount?.isOpted,
            item?.paCoverAmount?.isOpted
              ? item?.paCoverAmount?.premium
              : item?.paCoverAmount?.isOpted,
            item?.lossOfPersonalBelongings?.isOpted,
            item?.lossOfPersonalBelongings?.isOpted
              ? item?.lossOfPersonalBelongings?.premium
              : item?.lossOfPersonalBelongings?.isOpted,
            item?.zeroDepriciationCover?.isOpted,
            item?.zeroDepriciationCover?.isOpted
              ? item?.zeroDepriciationCover?.premium
              : item?.zeroDepriciationCover?.isOpted,
            item?.keyReplacement?.isOpted,
            item?.keyReplacement?.isOpted
              ? item?.keyReplacement?.premium
              : item?.keyReplacement?.isOpted,

            item?.ncbDiscountAmount,
            item?.biFuelOd?.isOpted,
            item?.biFuelOd?.isOpted
              ? item?.biFuelOd?.premium
              : item?.biFuelOd?.isOpted,

            item?.biFuelTp?.isOpted,

            item?.biFuelTp?.isOpted
              ? item?.biFuelTp?.premium
              : item?.biFuelTp?.isOpted,
            item?.llToPaidDriver?.isOpted,
            item?.llToPaidDriver?.isOpted
              ? item?.llToPaidDriver?.premium
              : item?.llToPaidDriver?.isOpted,
            item?.caseId,
            item?.insurerId,
            item?.kycAdditionalData,
            item?.planId,
            item?.isTppdAvailable?.isOpted
              ? item?.isTppdAvailable?.premium
              : item?.isTppdAvailable?.isOpted,
            item?.loadingPremium ? item?.loadingPremium : false,
            '',
            '',
            item?.odTenure,
            item?.tpTenure,
            item?.ncbDiscountPercentage
          )
        )
      )
    )
  }

  useEffect(() => {
    if (additionCoverOpen) {
      console.log('Covers OPen')
      setChangeCoverFilter(true)
    } else {
      console.log('Addons OPen')
      setChnageAddonFilter(true)
    }
  }, [filter])

  useEffect(() => {
    if (changeCoverFilter && onMouseLeaveCover === false) {
      console.log('Cover Changes')

      let newStructureData = { ...motorRequest }
      console.log('filter', filter)
      for (let key in filter) {
        // if (key === "Consumables") {
        dispatchMotorQuote(key, filter[key])
        newStructureData[key] = filter[key]
      }

      // for (let key in filter) {
      //   newData.AddOns[key] = filter[key];
      //   dispatchQuickQuote(`AddOns.${key}`, filter[key]);
      // }
      console.log('newDATA FILTERED', newStructureData)
      handleFilteredInsurer(newStructureData)
      setChangeCoverFilter(false)
      setAddditionCoverOpen(false)
    }
  }, [onMouseLeaveCover])

  useEffect(() => {
    if (changeAddonFilter && onMouseLeave === false) {
      console.log('ADDON Changes')

      let newStructureData = { ...motorRequest }
      console.log('filter', filter)
      for (let key in filter) {
        dispatchMotorQuote(key, filter[key])
        newStructureData[key] = filter[key]
      }

      console.log('newDATA FILTERED', newStructureData)
      handleFilteredInsurer(newStructureData)
      setChnageAddonFilter(false)
    }
  }, [onMouseLeave])

  // const kycConfig = async () => {
  //
  //   for (const insurer of QuickQouteResult) {
  //     if (
  //       insurer?.Api_name === "Tata AIG General Insurance Co. Ltd." ||
  //       "ICICI Lombard General Insurance Co. Ltd." ||
  //       "Bajaj Allianz General Insurance Co. Ltd." ||
  //       "HDFC" ||
  //       "Zurich Kotak General Insurance Company (India) Limited" ||
  //       "Future Generali India Insurance Company Limited"
  //     ) {
  //       const response = await getKycConfig(
  //         item?.insurerId,
  //         motorRequest?.customerType
  //       );
  //       console.log("response kyc config", response);
  //       dispatchKycConfig(response?.data);
  //       response?.data?.kycConfig?.isCkycAllowed
  //         ? dispatchMotorQuote("kycType", kycType?.ckyc)
  //         : response?.data?.kycConfig?.isOvdAllowed
  //         ? dispatchMotorQuote("kycType", kycType?.ovd)
  //         : dispatchMotorQuote("kycType", kycType?.ckyc);
  //     }
  //   }
  // };

  const kycConfig = async item => {
    try {
      const response = await getKycConfig(
        item?.insurerId,
        motorRequest?.customerType
      )

      if (!response) {
        throw new Error('No response from getKycConfig')
      }

      console.log('response kyc config', response)

      dispatchKycConfig(response?.data)
      setKycConfigData(true)
      dispatchProcessFlowMotor('step8.kycConfig', response?.data)
      console.log('step8.kycConfig', response?.data)

      if (response?.data?.kycConfig?.isCkycAllowed) {
        dispatchMotorQuote('kycType', kycType?.ckyc)
      } else if (response?.data?.kycConfig?.isOvdAllowed) {
        dispatchMotorQuote('kycType', kycType?.ovd)
      } else {
        dispatchMotorQuote('kycType', kycType?.ckyc)
      }
    } catch (error) {
      console.error(
        'Error fetching KYC config for insurer:',
        // insurer?.Api_name,
        error
      )
    }
  }

  // const getKycycStatus = async () => {
  //   const response = await getKycStatus(motorRequest, planDetail)
  //   console.log('getKycycStatus ', response)
  //   console.log('response kyc status', response)
  //   // response?.data?.kycStatus === "success"
  //   //   ? setKycStatus(true)
  //   //   : response?.data?.kycStatus === "pending"
  //   //   ? setKycStatus(true)
  //   //   : setKycStatus(false);
  //   if (response?.data?.kycStatus === '') {
  //     setKycStatus(false)
  //     dispatchMotorQuote('kycStatus', '')
  //     dispatchMotorQuote('kycData', '')
  //   } else {
  //     setKycStatus(true)
  //   }
  // }

  // const handlePAModal = (item) => {
  //   console.log("itttem", item);

  //   kycConfig();
  //   getKycycStatus();

  //   dispatchQuickQuote("EndDate", apiRequestQQ.PolicyEndDate);

  //   setPlanDetail(item);
  //   setItemState(item);

  //   if (item?.Api_name === "Magma" || item?.Api_name === "hdfc") {
  //     if (
  //       insurer?.Api_name === 'Tata AIG General Insurance Co. Ltd.' ||
  //       'ICICI Lombard General Insurance Co. Ltd.' ||
  //       'Bajaj Allianz General Insurance Co. Ltd.' ||
  //       'HDFC' ||
  //       'Zurich Kotak General Insurance Company (India) Limited' ||
  //       'Future Generali India Insurance Company Limited'
  //     ) {
  //       const response = await getKycConfig(
  //         item?.insurerId,
  //         motorRequest?.customerType
  //       )
  //       console.log('response kyc config', response)
  //       dispatchKycConfig(response?.data)
  //       response?.data?.kycConfig?.isCkycAllowed
  //         ? dispatchMotorQuote('kycType', kycType?.ckyc)
  //         : response?.data?.kycConfig?.isOvdAllowed
  //         ? dispatchMotorQuote('kycType', kycType?.ovd)
  //         : dispatchMotorQuote('kycType', kycType?.ckyc)
  //     }
  //   }
  // }

  // const kycConfig = async () => {
  //   try {
  //     const response = await getKycConfig(
  //       planDetail?.insurerId,
  //       motorRequest?.customerType
  //     );

  //     if (!response) {
  //       throw new Error("No response from getKycConfig");
  //     }

  //     console.log("response kyc config", response);

  //     dispatchKycConfig(response?.data);
  //     setKycConfigData(true);
  //     dispatchProcessFlowMotor("step8.kycConfig", response?.data);
  //     console.log("step8.kycConfig", response?.data);

  //     if (response?.data?.kycConfig?.isCkycAllowed) {
  //       dispatchMotorQuote("kycType", kycType?.ckyc);
  //     } else if (response?.data?.kycConfig?.isOvdAllowed) {
  //       dispatchMotorQuote("kycType", kycType?.ovd);
  //     } else {
  //       dispatchMotorQuote("kycType", kycType?.ckyc);
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error fetching KYC config for insurer:",
  //       insurer?.Api_name,
  //       error
  //     );
  //   }
  // };

  // const getKycycStatus = async () => {
  //   const response = await getKycStatus(motorRequest, planDetail)
  //   console.log('getKycycStatus ', response)
  //   console.log('response kyc status', response)
  //   // response?.data?.kycStatus === "success"
  //   //   ? setKycStatus(true)
  //   //   : response?.data?.kycStatus === "pending"
  //   //   ? setKycStatus(true)
  //   //   : setKycStatus(false);
  //   if (response?.data?.kycStatus === '') {
  //     setKycStatus(false)
  //     dispatchMotorQuote('kycStatus', '')
  //     dispatchMotorQuote('kycData', '')
  //   } else {
  //     setKycStatus(true)
  //   }
  // }

  const getKycycStatus = async item => {
    const response = await getKycStatus(motorRequest, item)
    console.log('response kyc status', response)
    // response?.data?.kycStatus === "success"
    //   ? setKycStatus(true)
    //   : response?.data?.kycStatus === "pending"
    //   ? setKycStatus(true)
    //   : setKycStatus(false);
    if (response?.data?.kycStatus === '') {
      setKycStatus(false)
      dispatchMotorQuote('kycStatus', '')
      dispatchMotorQuote('kycData', '')
      dispatchProcessFlowMotor('step8.kycStatus', '')
      let dataForKyc = { ...processFlowObjMotor?.step8?.kycAndUserDetails }
      if (dataForKyc?.kycStatus === 'completed') {
        dataForKyc.kycStatus = ''
      }
      console.log('dataForKyc', dataForKyc)
      console.log('dataForKyc updated', dataForKyc)
      console.log('motorRequest', motorRequest)
      let newMotorRequest = { ...motorRequest }
      newMotorRequest.kycStatus = ''
      console.log('newMotorRequest', newMotorRequest)
      const newData = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        step: 'step7',
        motorRequest: { ...newMotorRequest },
        processDiscription: 'quotation',
        step7: {
          ...processFlowObjMotor.step7,
          motorRequest: { ...newMotorRequest },
          QuickQouteResult: { ...QuickQouteResult }
        }
      }
      processFlow(newData)
      console.log('newData premiumBreakup', newData)
    } else {
      setKycStatus(true)
      dispatchProcessFlowMotor('step8.kycStatus', 'completed')
    }
  }

  const handlePAModal = item => {
    console.log('itttem', item)

    kycConfig(item)

    getKycycStatus(item)

    dispatchQuickQuote('EndDate', apiRequestQQ.PolicyEndDate)

    setPlanDetail(item)
    setItemState(item)

    if (item?.Api_name === 'Magma' || item?.Api_name === 'hdfc') {
      if (
        apiRequestQQ.NewPolicyType === 'ODOnly' ||
        apiRequestQQ?.CustomerType === 'COMPANY'
      ) {
        if (filter.PersonalAccident !== true) {
          if (filter?.ZeroDepriciationCover || filter?.InvoiceCover) {
            setMagmaImg(item.Logo)
            setModalOpen2(true)
          } else {
            toggleModal2()
            setPlanDetail(item)
            setItemState(item)
          }
        } else if (filter.PersonalAccident === true) {
          toggleModal2()
          setPlanDetail(item)
          setItemState(item)
        }
      } else {
        setMagmaImg(item.Logo)
        setModalOpen2(true)
      }
    } else if (
      item?.Api_name === 'ICICI Lombard General Insurance Co. Ltd.' ||
      'Bajaj Allianz General Insurance Co. Ltd.' ||
      'Zurich Kotak General Insurance Company (India) Limited' ||
      'Future Generali India Insurance Company Limited'
    ) {
      toggleModal3()
      setPlanDetail3(item)
      setItemState3(item)
    } else if (
      item?.Api_name === 'Tata AIG General Insurance Co. Ltd.' ||
      'HDFC'
    ) {
      dispatchMotorQuote('isMotorRequestTrue', true)

      if (motorRequest.zeroDepriciationCover || item.invoiceCover) {
        setModalOpen2(true)

        // toggleModal3();
        // setPlanDetail3(item)
        // setItemState3(item)
      } else {
        toggleModal3()
        setPlanDetail3(item)
        setItemState3(item)
      }
    } else {
      toggleModal2()
      setPlanDetail(item)
      setItemState(item)
    }
  }

  return (
    <div>
      {/* <SideBar /> */}
      {/* <section class='home-section'> */}
      <div class='home-content'>
        <div id='main_div'>
          <Header />
        </div>
      </div>
      <div className='main-quote-list'>
        <div className='row'>
          <section className='col-md-12 col-lg-3'>
            <div class='invoice_sec'>
              <div class='row m-0 m-lg-0 p-0 align-items-lg-baseline'>
                {/* <div class="col-1 d-block d-lg-none ps-0 pe-0">
                  <a href="">
                    <img
                      width="32"
                      height="32"
                      src="https://static.pbcdn.in/car-cdn/car2.0/back-btn.svg"
                      alt="car info"
                    />
                  </a>
                </div> */}
                <div class='col-10 ps-3 ps-md-0 ps-lg-0'>
                  <h2 class='sec_heading'>
                    {motorRequest?.vehicleType === VEHICLE_TYPE.FOUR_WHEELER ? (
                      <img
                        src='/assetss/images/pvt_car.png'
                        // height={29}
                        width={30}
                      />
                    ) : (
                      <img
                        src='/assetss/images/Bike1.png'
                        // height={29}
                        width={30}
                      />
                    )}
                    <span className='vehicle_modal ps-4'>
                      {motorRequest?.makeName} {motorRequest?.modelName}{' '}
                      {motorRequest?.variantName}
                    </span>
                  </h2>
                </div>
                <div class='col-2 col-lg-2 ps-0 pe-md-0 text-end'>
                  <a
                    class='edit'
                    onClick={() => {
                      setEditDetails(true)
                      setModalEdit(true)
                    }}
                  >
                    Edit
                  </a>
                </div>
              </div>
              <div class='line'></div>
              <div
                class='invoice_list invoice_year'
                style={{ justifyContent: 'space-between' }}
              >
                <div class='row'>
                  <div class='col-12 col-lg-7 pe-lg-0'>
                    <p class='invoice_content'>Registration Number</p>
                  </div>
                  <div class='col-12 col-lg-5 ps-lg-0'>
                    <span class='invoce_des'>
                      {motorRequest?.registrationNumber}
                    </span>
                  </div>
                </div>
                <div class='row'>
                  <div class='col-12 col-lg-7 pe-lg-0'>
                    <p class='invoice_content'>RTO</p>
                  </div>
                  <div class='col-12 col-lg-5 ps-lg-0'>
                    <span class='invoce_des'>
                      {motorRequest?.rtoCode} ({motorRequest?.registrationCity})
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {apiRequestQQ?.NewPolicyType !== 'ThirdParty' ? (
              <div class='invoice_sec'>
                <div class='row m-0 m-lg-0 p-0 align-items-lg-baseline'>
                  {/* <div class="col-1 d-block d-lg-none ps-0 pe-0">
                    <a href="">
                      <img
                        width="32"
                        height="32"
                        src="https://static.pbcdn.in/car-cdn/car2.0/back-btn.svg"
                        alt="car info"
                      />
                    </a>
                  </div> */}
                  <div class='col-10 ps-3 ps-md-0 ps-lg-0'>
                    <h2 class='sec_heading'>Insured value (IDV)</h2>
                  </div>
                  <div class='col-2 col-lg-2 ps-0 pe-md-0 text-end'>
                    <a
                      href='#!'
                      class='edit'
                      onClick={() => {
                        // handleIinitialIDV();
                        setIdvModal(true)
                      }}
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <div class='line'></div>
                <div class='invoice_list'>
                  <div class='row'>
                    <div class='col-12 col-lg-7 pe-lg-0'>
                      <p class='invoice_content'>
                        Currently Set For Lowest Price:{' '}
                      </p>
                    </div>
                    <div class='col-12 col-lg-5 ps-lg-0'>
                      <span class='invoce_des'>
                        {/* {motorRequest?.idv ? (
                          <strong>{motorRequest?.idv}</strong>
                        ) : (
                          <strong>{val[0]}</strong>
                        )} */}
                        {motorRequest?.idv}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {(motorRequest?.newPolicyType === POLICY_TYPE.COMPREHENSIVE ||
              motorRequest?.newPolicyType === POLICY_TYPE.ODONLY) && (
              <div class='invoice_sec'>
                <div class='row m-0 m-lg-0 p-0 align-items-lg-baseline'>
                  {/* <div class="col-1 d-block d-lg-none ps-0 pe-0">
                    <a href="">
                      <img
                        width="32"
                        height="32"
                        src="https://static.pbcdn.in/car-cdn/car2.0/back-btn.svg"
                        alt="car info"
                      />
                    </a>
                  </div> */}
                  <div class='col-10 ps-3 ps-md-0 ps-lg-0'>
                    <h2 class='sec_heading'>No claim bonus</h2>
                  </div>
                </div>
                <div class='line'></div>
                <div class='invoice_list'>
                  {/* <div class="row">
                    <div class="col-12 col-lg-7 pe-lg-0">
                      <p class="invoice_content">Currently applicable NCB: </p>
                    </div>
                    <div class="col-12 col-lg-5 ps-lg-0">
                      <span class="invoce_des">
                        {motorRequest.isPreviousInsuranceClaimed
                          ? 0
                          : motorRequest?.prevNoClaimBonus === 0
                          ? "20"
                          : motorRequest?.prevNoClaimBonus === 20
                          ? "25"
                          : motorRequest?.prevNoClaimBonus === 25
                          ? "35"
                          : motorRequest?.prevNoClaimBonus === 35
                          ? "45"
                          : motorRequest?.prevNoClaimBonus === 45
                          ? "50"
                          : ""}
                        %
                      </span>
                    </div>
                  </div> */}
                  <div class='row'>
                    <div class='col-12 col-lg-7 pe-lg-0'>
                      <p class='invoice_content'> Previous Year NCB: </p>
                    </div>
                    <div class='col-12 col-lg-5 ps-lg-0'>
                      <span class='invoce_des'>
                        {motorRequest?.prevNoClaimBonus}%
                      </span>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-12 col-lg-7 pe-lg-0'>
                      <p class='invoice_content'> Claims in last policy: </p>
                    </div>
                    <div class='col-12 col-lg-5 ps-lg-0'>
                      <span class='invoce_des'>
                        {motorRequest.isPreviousInsuranceClaimed ? 'Yes' : 'No'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div class='invoice_sec'>
              <div class='row m-0 m-lg-0 p-0 align-items-lg-baseline'>
                {/* <div class="col-1 d-block d-lg-none ps-0 pe-0">
                  <a href="">
                    <img
                      width="32"
                      height="32"
                      src="https://static.pbcdn.in/car-cdn/car2.0/back-btn.svg"
                      alt="car info"
                    />
                  </a>
                </div> */}
                <div class='col-10 ps-3 ps-md-0 ps-lg-0'>
                  <h2 class='sec_heading'>Plan</h2>
                </div>
              </div>
              <div class='line'></div>
              <div class='invoice_list'>
                <div class='row'>
                  <div class='col-12 col-lg-7 pe-lg-0'>
                    <p class='invoice_content'> Plan Duration:</p>
                  </div>
                  <div class='col-12 col-lg-5 ps-lg-0'>
                    <span class='invoce_des'>1 Year</span>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="no-shadow-card">
              <div className="linear-div" style={{ marginTop: 10 }}>
                <p className="semibold-text fs-2">Your Vehicle Details</p>
                <p
                  className="edit-btn fs-3"
                  onClick={() => {
                    setEditDetails(true);
                    setModalEdit(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Edit
                </p>
              </div>
              <div className="line"></div>
              <div
                className="linear-div"
                style={{
                  justifyContent: "flex-start",
                }}
              >
                {apiRequestQQ?.VehicleType === "4w" ? (
                  <img
                    src="/assetss/images/pvt_car.png"
                    height={29}
                    width={42}
                  />
                ) : (
                  <img src="/assetss/images/Bike1.png" height={29} width={42} />
                )}

                <p className="small-text fs-4" style={{ marginLeft: 10 }}>
                  Vehicle <br />
                  <p style={{ marginTop: 0 }} className="semibold-text fs-3">
                    {apiRequestQQ?.MakeName} {apiRequestQQ?.ModelName}
                  </p>
                </p>
              </div>
              <div className="linear-div">
                <p className="small-text fs-4">
                  Year <br />{" "}
                  <p className="semibold-text fs-3">
                    {apiRequestQQ?.RegistrationYear}
                  </p>
                </p>
                <p className="small-text fs-4">
                  RTO <br />{" "}
                  <p className="semibold-text fs-3">
                    {apiRequestQQ?.RTOCityName} (
                    {apiRequestQQ?.RegistrationCity})
                  </p>
                </p>
              </div>
            </div> */}

            {/* {apiRequestQQ?.NewPolicyType !== "ThirdParty" ? (
              <div className="no-shadow-card">
                <div className="linear-div">
                  <p className="semibold-text fs-2">Insured value (IDV)</p>{" "}
                  <p
                    className="edit-btn fs-3"
                    onClick={() => {
                      handleIinitialIDV();
                      setIdvModal(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Edit
                  </p>
                </div>
                <div className="line"></div>
                <p
                  className="fs-3"
                  style={{ margin: 10, fontSize: 14, fontWeight: 300 }}
                >
                  Currently Set For Lowest Price:{" "}
                  {apiRequestQQ?.Idv ? (
                    <strong>
                      {apiRequestQQ?.Idv
                        ? apiRequestQQ?.Idv
                        : range?.minimumIdv}
                    </strong>
                  ) : (
                    <strong>{val[0]}</strong>
                  )}
                </p>
              </div>
            ) : (
              ""
            )} */}

            {/* {(apiRequestQQ?.NewPolicyType === "Comprehensive" ||
              apiRequestQQ?.NewPolicyType === "ODOnly") && (
              <div className="no-shadow-card">
                <div className="linear-div">
                  <p className="semibold-text fs-2">No claim bonus</p>
                </div>
                <div className="line"></div>
                <p
                  className="fs-3"
                  style={{
                    margin: 10,
                    fontSize: 14,
                    fontWeight: 300,
                    lineHeight: 1.8,
                  }}
                >
                  Currently applicable NCB:{" "}
                  {apiRequestQQ.PreviousInsuranceClaimed === "true"
                    ? 0
                    : apiRequestQQ?.CurrentNoClaimBonus
                    ? apiRequestQQ?.CurrentNoClaimBonus
                    : apiRequestQQ?.PreviousNoClaimBonus === "0"
                    ? "20"
                    : apiRequestQQ?.PreviousNoClaimBonus === "20"
                    ? "25"
                    : apiRequestQQ?.PreviousNoClaimBonus === "25"
                    ? "35"
                    : apiRequestQQ?.PreviousNoClaimBonus === "35"
                    ? "45"
                    : apiRequestQQ?.PreviousNoClaimBonus === "45"
                    ? "50"
                    : ""}
               
                  % <br />
                  Previous Year NCB:{" "}
                  {(apiRequestQQ?.CurrentNoClaimBonus === "0"
                    ? "0"
                    : apiRequestQQ?.CurrentNoClaimBonus === "20"
                    ? "0"
                    : apiRequestQQ?.CurrentNoClaimBonus === "25"
                    ? "20"
                    : apiRequestQQ?.CurrentNoClaimBonus === "35"
                    ? "25"
                    : apiRequestQQ?.CurrentNoClaimBonus === "45"
                    ? "35"
                    : apiRequestQQ?.CurrentNoClaimBonus === "50"
                    ? "45"
                    : "") || apiRequestQQ?.PreviousNoClaimBonus}
                  %<br />
                  Claims in last policy:{" "}
                  {apiRequestQQ.PreviousInsuranceClaimed === "true"
                    ? "Yes"
                    : "No"}
                </p>
              </div>
            )} */}

            {/* <div className="no-shadow-card">
              <p className="semibold-text fs-2" style={{ margin: 10 }}>
                Plan Duration: 1 year
              </p>
            </div> */}
          </section>

          <section className='col-md-12 col-lg-9 mt-4'>
            <div className='bikecoverplanform'>
              <form>
                <ul className='row'>
                  <div className='row mx-0 px-0'>
                    <li className='col col-xl-3 col-lg-3 col-md-6 col-12'>
                      {/* <label>Eligible NCB</label> */}
                      <select
                        // className={`form-select ${
                        //   apiRequestQQ?.NewPolicyType === "ThirdParty"
                        //     ? "disable_addon"
                        //     : ""
                        // }`}
                        className={`form-select ${
                          motorRequest?.newPolicyType ===
                            POLICY_TYPE.THIRDPARTY ||
                          apiRequestQQ?.NewPolicyType === 'Bundled' ||
                          motorRequest.isPreviousInsuranceClaimed
                            ? 'disable_addon'
                            : ''
                        }`}
                        onChange={e => {
                          handleChangeNCB(e?.target?.value)
                          // handleIinitialIDV();
                        }}
                      >
                        <option key='' value=''>
                          Change Previous NCB
                        </option>
                        <option key='0' value={0}>
                          0 %
                        </option>
                        <option key='20' value={20}>
                          20 %
                        </option>
                        <option key='25' value={25}>
                          25 %
                        </option>
                        <option key='35' value={35}>
                          35 %
                        </option>
                        <option key='45' value={45}>
                          45 %
                        </option>
                        <option key='50' value={50}>
                          50 %
                        </option>
                      </select>
                    </li>

                    <li className='col col-xl-3 col-lg-3 col-md-6 col-12'>
                      <div
                        className='form-select'
                        onClick={() => {
                          setAddditionCoverOpen(true)
                          setOnMouseLeaveCover(true)
                          setCoverDisplay(true)
                          // handleIinitialIDV();
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {/* <p
                        className="mb-2 py-4 fs-3"
                        style={{
                          fontWeight: 400,
                          border: "1px solid #00b0cb",
                          borderRadius: "8px",
                          color: "#969696",
                          paddingLeft: "15px",
                          background: "white",
                        }}
                      > */}
                        Additional Covers
                        {/* <i className="fa fa-caret-down fs-3 align-middle position-absolute ms-0" /> */}
                        {/* </p> */}
                      </div>

                      <div
                        id='addcover'
                        className={`idvcustom shadow ${
                          coverDisplay ? 'idvcustomshow' : ''
                        }`}
                        onMouseLeave={() => {
                          setCoverDisplay(false)
                          setOnMouseLeaveCover(false)
                        }}
                      >
                        <button
                          type='button'
                          class='btn-close position-absolute d-lg-none d-block'
                          style={{ top: 10, right: 10 }}
                        ></button>

                        {(motorRequest?.newPolicyType ===
                        POLICY_TYPE.COMPREHENSIVE
                          ? Covers
                          : motorRequest?.newPolicyType ===
                            POLICY_TYPE.THIRDPARTY
                          ? tpCovers
                          : motorRequest?.newPolicyType === POLICY_TYPE.ODONLY
                          ? odCovers
                          : Covers
                        ).map((item, index) => (
                          <>
                            <div
                              className='form-check d-flex flex-column mb-3'
                              style={{ paddingLeft: '30px' }}
                            >
                              <input
                                type='checkbox'
                                value={item.value}
                                className={`form-check-input fs-2`}
                                name={item.value}
                                checked={filter[item.value]}
                                id={'day' + index}
                                onChange={e => handleChangeFilter(e)}
                              />

                              <div
                                className={`form-check-label  fs-3`}
                                style={{
                                  paddingLeft: '10px',
                                  marginTop: '-24px'
                                }}
                                htmlFor={'day' + index}
                              >
                                {item.label}
                              </div>
                              {item.type == 'input' &&
                                filter[item.value] === true && (
                                  <>
                                    {item.value === 'isPassengerCover' ? (
                                      <select
                                        className='form-control'
                                        style={{
                                          marginTop: '5px'
                                        }}
                                        name={item.inputName}
                                        // value={filter[item.inputName]}
                                        onChange={e => handleChangeValue(e)}
                                        value={filter[item.inputName]}
                                      >
                                        {Object.entries(item.options).map(
                                          ([key, value]) => (
                                            <option key={key} value={value}>
                                              {value}
                                            </option>
                                          )
                                        )}

                                        {/* <option value="0">
                                          Select Sum Insured
                                        </option>
                                        <option value="10000">10000</option>
                                        <option value="20000">20000</option>
                                        <option value="30000">30000</option>
                                        <option value="40000">40000</option>
                                        <option value="50000">50000</option>
                                        <option value="100000">100000</option>
                                        {motorRequest.vehicleType ==
                                          VEHICLE_TYPE.FOUR_WHEELER && (
                                          <>
                                            <option value="120000">
                                              120000
                                            </option>
                                            <option value="150000">
                                              150000
                                            </option>
                                            <option value="200000">
                                              200000
                                            </option>
                                          </>
                                        )} */}
                                      </select>
                                    ) : item.value ==
                                      'liabilitiesToPaidDriver' ? (
                                      <select
                                        className='form-control'
                                        style={{
                                          marginTop: '5px'
                                        }}
                                        name={item.inputName}
                                        value={filter[item.inputName]}
                                        onChange={e => handleChangeValue(e)}
                                      >
                                        {Object.entries(item.options).map(
                                          ([key, value]) => (
                                            <option key={key} value={value}>
                                              {value}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    ) : (
                                      <input
                                        type='number'
                                        min={item.min}
                                        name={item.inputName}
                                        value={filter[item.inputName]}
                                        onChange={e => handleChangeValue(e)}
                                        className='form-control'
                                        style={{
                                          marginTop: '5px'
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                            </div>
                          </>
                        ))}
                      </div>
                    </li>

                    {
                      <li className='col col-xl-3 col-lg-3 col-md-6 col-12'>
                        <div
                          className={`form-select ${
                            motorRequest?.newPolicyType ===
                            POLICY_TYPE.THIRDPARTY
                              ? 'disable_addon'
                              : ''
                          }`}
                          onClick={() => {
                            setaddOnDisplay(true)
                            setOnMouseLeave(true)
                            // handleIinitialIDV();
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          {/* <p
                        className="mb-2 py-4 fs-3"
                        style={{
                          fontWeight: 400,
                          border: "1px solid #00b0cb",
                          borderRadius: "8px",
                          color: "#969696",
                          paddingLeft: "15px",
                          background: "white",
                        }}
                      > */}
                          Addons
                          {/* <i className="fa fa-caret-down fs-3 align-middle mb-2 ms-3" /> */}
                          {/* </p> */}
                        </div>
                        <div
                          id='addon'
                          className={`idvcustom shadow ${
                            addOnDisplay ? 'idvcustomshow' : ''
                          }`}
                          onMouseLeave={() => {
                            setaddOnDisplay(false)
                            setOnMouseLeave(false)
                          }}
                        >
                          <button
                            type='button'
                            className='btn-close position-absolute d-lg-none d-block'
                            style={{ top: 10, right: 10 }}
                          ></button>
                          {addOnsData.map((item, index) => (
                            <div className='form-check mb-3 fs-2'>
                              <input
                                type='checkbox'
                                value={item.value}
                                className='form-check-input'
                                name={item.value}
                                checked={filter[item.value]}
                                id={'day' + index}
                                onChange={e => handleChangeFilter(e)}
                              />
                              <div
                                className='form-check-label fs-3 mx-3'
                                htmlFor={'day' + index}
                              >
                                {item.label}
                              </div>
                              {item.type == 'input' &&
                                filter[item.value] === true && (
                                  <input
                                    type='number'
                                    min={item.min}
                                    name={item.inputName}
                                    value={filter[item.inputName]}
                                    onChange={e => handleChangeValue(e)}
                                    className='form-control'
                                  />
                                )}
                            </div>
                          ))}
                        </div>

                        <div className='modalashadow'></div>
                      </li>
                    }
                  </div>

                  <div className='row mx-0 px-0'>
                    <li className='coverbutton'>
                      <button
                        className={`${
                          motorRequest?.newPolicyType ===
                          POLICY_TYPE.COMPREHENSIVE
                            ? 'active'
                            : motorRequest?.isVehicleNew === true
                            ? 'disable_addon'
                            : ''
                        } 
                         ${
                           motorRequest.vehicleType ===
                           VEHICLE_TYPE.FOUR_WHEELER
                             ? yearDiff > 3
                               ? ''
                               : 'disable_addon'
                             : yearDiff > 5
                             ? ''
                             : 'disable_addon'
                         }`}
                        // className={
                        //   apiRequestQQ?.NewPolicyType === "Comprehensive"
                        //     ? "active"
                        //     : apiRequestQQ?.IsVehicleNew === true
                        //     ? "disable_addon"
                        //     : ""
                        // }
                        // style={{ marginRight: "10px" }}
                        onClick={e => {
                          e.preventDefault()
                          handleFilterCover(POLICY_TYPE.COMPREHENSIVE)
                          // handleIinitialIDV();
                        }}
                      >
                        Comprehensive cover
                      </button>
                      <button
                        // className={
                        //   apiRequestQQ?.NewPolicyType === "ThirdParty"
                        //     ? //  || apiRequestQQ?.IsVehicleNew === false
                        //       "active"
                        //     : ""
                        // }
                        className={`${
                          motorRequest?.newPolicyType === POLICY_TYPE.THIRDPARTY
                            ? 'active'
                            : ''
                        } 
                         ${
                           motorRequest.vehicleType ===
                           VEHICLE_TYPE.FOUR_WHEELER
                             ? yearDiff > 3
                               ? ''
                               : 'disable_addon'
                             : yearDiff > 5
                             ? ''
                             : 'disable_addon'
                         }`}
                        style={{ marginLeft: '0px' }}
                        onClick={e => {
                          e.preventDefault()
                          handleFilterCover(POLICY_TYPE.THIRDPARTY)
                          // handleIinitialIDV();
                        }}
                      >
                        Third party only cover
                      </button>
                      {motorRequest.isVehicleNew === false ? (
                        <button
                          // disabled
                          className={`${
                            motorRequest?.newPolicyType === POLICY_TYPE.ODONLY
                              ? 'active'
                              : ''
                          } 
                           ${
                             motorRequest.vehicleType ===
                             VEHICLE_TYPE.FOUR_WHEELER
                               ? yearDiff < 3
                                 ? ''
                                 : 'disable_addon'
                               : yearDiff > 5
                               ? 'disable_addon'
                               : ''
                           }`}
                          // style={{ marginLeft: "10px" }}
                          onClick={e => {
                            e.preventDefault()
                            handleFilterCover(POLICY_TYPE.ODONLY)
                            // handleIinitialIDV();
                          }}
                        >
                          OD only cover
                        </button>
                      ) : (
                        <button className='disable_addon'>OD only cover</button>
                      )}
                    </li>
                  </div>
                </ul>
              </form>
            </div>

            {/* <TextField id="i" label="Bike IDV" variant="outlined" /> */}
            <p className='headline'>
              {QuickQouteResult?.length}{' '}
              {motorRequest?.newPolicyType === POLICY_TYPE.COMPREHENSIVE
                ? 'Comprehensive'
                : motorRequest?.newPolicyType === POLICY_TYPE.THIRDPARTY
                ? 'Third Party'
                : motorRequest?.newPolicyType === POLICY_TYPE.ODONLY
                ? 'Own Damage'
                : ''}{' '}
              Plans
            </p>
            <p className='sub-headline'>
              <span>
                {motorRequest?.newPolicyType === POLICY_TYPE.COMPREHENSIVE
                  ? 'Covers Damages To Your Vehicle And Third-Party'
                  : motorRequest?.newPolicyType === POLICY_TYPE.THIRDPARTY
                  ? 'Covers Damages To Third-Party'
                  : motorRequest?.newPolicyType === POLICY_TYPE.ODONLY
                  ? 'Covers Damages To Your Vehicle'
                  : ''}
              </span>
              <span style={{ paddingRight: 4, cursor: 'pointer' }} onClick={()=> toggleShareQuoteLinkModal()} >
                <MdMoreHoriz />
              </span>
            </p>

            {console.log('QuickQouteResult', QuickQouteResult)}
            {QuickQouteResult && QuickQouteResult.length > 0 ? (
              QuickQouteResult.map((item, index) => {
                if (typeof item?.FinalPremium === 'number') {
                  var priceWithoutINR = item?.FinalPremium.toString().replace(
                    'INR ',
                    ''
                  )
                } else if (typeof item?.FinalPremium === 'string') {
                  var priceWithoutINR = item?.FinalPremium.replace('INR ', '')
                } else {
                  var priceWithoutINR = item?.FinalPremium
                }
                {
                  console.log('ittt', item)
                }
                var numbers = item.MinMaxIdv && item.MinMaxIdv.split(' - ')
                var minIDV =
                  item.Api_name ===
                  ('Tata AIG General Insurance Co. Ltd.' ||
                    'ICICI Lombard General Insurance Co. Ltd.' ||
                    'Bajaj Allianz General Insurance Co. Ltd.' ||
                    'HDFC' ||
                    'Zurich Kotak General Insurance Company (India) Limited' ||
                    'Future Generali India Insurance Company Limited')
                    ? Math.round(item.MinIdv)
                    : Math.round(numbers[0])
                {
                  console.log('numbers', numbers)
                }
                {
                  console.log('numbers', numbers[0])
                }
                {
                  console.log('numbers', numbers[1])
                }
                {
                  console.log('numbers', numbers[2])
                }
                {
                  console.log('numbers', numbers[3])
                }
                var maxIDV =
                  item.Api_name ===
                  ('Tata AIG General Insurance Co. Ltd.' ||
                    'ICICI Lombard General Insurance Co. Ltd.' ||
                    'Bajaj Allianz General Insurance Co. Ltd.' ||
                    'HDFC' ||
                    'Zurich Kotak General Insurance Company (India) Limited' ||
                    'Future Generali India Insurance Company Limited')
                    ? Math.round(item.MaxIdv)
                    : Math.round(numbers[1])
                {
                  console.log('ff', maxIDV)
                }
                {
                  console.log('ittt', item)
                }
                var numbers = item.MinMaxIdv && item.MinMaxIdv.split(' - ')
                var minIDV =
                  item.Api_name ===
                  ('Tata AIG General Insurance Co. Ltd.' ||
                    'ICICI Lombard General Insurance Co. Ltd.' ||
                    'Bajaj Allianz General Insurance Co. Ltd.' ||
                    'HDFC' ||
                    'Zurich Kotak General Insurance Company (India) Limited' ||
                    'Future Generali India Insurance Company Limited')
                    ? Math.round(item.MinIdv)
                    : Math.round(numbers[0])
                {
                  console.log('numbers', numbers)
                }
                {
                  console.log('numbers', numbers[0])
                }
                {
                  console.log('numbers', numbers[1])
                }
                {
                  console.log('numbers', numbers[2])
                }
                {
                  console.log('numbers', numbers[3])
                }
                var maxIDV =
                  item.Api_name ===
                  ('Tata AIG General Insurance Co. Ltd.' ||
                    'ICICI Lombard General Insurance Co. Ltd.' ||
                    'Bajaj Allianz General Insurance Co. Ltd.' ||
                    'HDFC' ||
                    'Zurich Kotak General Insurance Company (India) Limited' ||
                    'Future Generali India Insurance Company Limited')
                    ? Math.round(item.MaxIdv)
                    : Math.round(numbers[1])

                {
                  console.log('ff', maxIDV)
                }

                return (
                  <React.Fragment key={index}>
                    <div className='shadow-card'>
                      <div className='col-12 d-flex justify-content-between'>
                        <img className='brand-image' src={item?.Logo} />

                        {motorRequest?.newPolicyType !==
                        POLICY_TYPE.THIRDPARTY ? (
                          <>
                            <p
                              className='semibold-text col-1 idvText'
                              style={{ textAlign: 'center' }}
                            >
                              IDV <br /> <strong>{item?.idv}</strong>
                            </p>
                            <p
                              className='semibold-text col-4 minMaxIdvRemove'
                              style={{ textAlign: 'center' }}
                            >
                              Min-Max <br />{' '}
                              <strong>
                                {' '}
                                {minIDV}
                                {' - '}
                                {maxIDV}
                              </strong>
                            </p>
                          </>
                        ) : (
                          <p
                            className='col-5 fs-2 my-auto'
                            style={{
                              textAlign: 'center',
                              fontWeight: 'lighter'
                            }}
                          >
                            <strong>Cover: </strong>Third Party
                          </p>
                        )}

                        <div
                          className='price-div col-2'
                          onClick={() => {
                            handlePAModal(item)
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          {/* <p
                                    className='price-txt'
                                    onClick={() => selectUserPlan(item)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {item?.FinalPremium}
                                  </p> */}
                          <p
                            className='price-txt'
                            onClick={() => setModelIdv(item)}
                          >
                            &#8377;{Math.round(priceWithoutINR)}
                          </p>
                        </div>
                      </div>

                      {/* <div className='row mt-2 pl-6' style={{ marginLeft: '10px' }}> */}

                      <div
                        className='col-12'
                        // style={{ marginLeft: "50px", fontSize: "13px" }}
                      >
                        <div className='addon'>
                          <div className='accordion' id='accordionExample'>
                            <div className='accordion-item'>
                              <h2
                                className='accordion-header'
                                id={`heading${index}`}
                              >
                                <div
                                  className='acc'
                                  // data-bs-toggle="collapse"
                                  data-bs-target={`#collapse${index}`}
                                  aria-expanded='false'
                                  aria-controls={`#collapse${index}`}
                                >
                                  Applied Addons
                                </div>
                              </h2>
                              <div
                                id={`collapse${index}`}
                                className='accordion-collapse collapse show'
                                aria-labelledby={`heading${index}`}
                                data-bs-parent='#accordionExample'
                              >
                                <div className='accordion-body'>
                                  <div className='product-select'>
                                    <ul className='product_box'>
                                      {(motorRequest?.personalAccident ||
                                        item?.PersonalAccident) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  !item.PersonalAccident
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Personal Accident
                                          </span>
                                        </li>
                                      )}

                                      {(motorRequest?.zeroDepriciationCover ||
                                        item?.ZeroDepriciationCover) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  !item.ZeroDepriciationCover
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Zero Depriciation Cover
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.roadSideAssistance ||
                                        item?.RoadSideAssistance) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  item.RoadSideAssistance ===
                                                  false
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Road Side Assistance
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.consumables ||
                                        item?.Consumables) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  item.Consumables === false
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Consumables
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.keyReplacement ||
                                        item?.KeyReplacement) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  item.KeyReplacement === false
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Key Replacement
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.invoiceCover ||
                                        item?.InvoiceCover) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  item.InvoiceCover === false
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Invoice Cover
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.ncbProtection ||
                                        item?.NCBProtection) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  item.NCBProtection === false
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            NCB Protection
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.lossOfPersonalBelongings ||
                                        item?.LossOfPersonalBelongings) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  item.LossOfPersonalBelongings ===
                                                  false
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Loss Of Personal Belongings
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.engineProtection ||
                                        item?.EngineProtection) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  item.EngineProtection ===
                                                  false
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Engine Protection
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.tyreProtection ||
                                        item?.TyreProtection) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  item.TyreProtection === false
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Tyre Protection
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.rimProtection ||
                                        item?.RimProtection) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  item.RimProtection === false
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Rim Protection
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.isElectricalAccessories ||
                                        item?.IsElectricalAccessories) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  item.IsElectricalAccessories ===
                                                  false
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Electrical Accessories
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.isNonElectricalAccessories ||
                                        item?.IsNonElectricalAccessories) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  item.IsNonElectricalAccessories ===
                                                  false
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Non Electrical Accessories
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.isCngAccessories ||
                                        item?.BiFuelOd ||
                                        item?.BiFuelTp) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  (item?.BiFuelOd ||
                                                    item?.BiFuelTp) === false
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Cng Accessories
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.liabilitiesToPaidDriver ||
                                        item?.liabilitiesToPaidDriver) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  item.liabilitiesToPaidDriver ===
                                                  false
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Liabilities To Paid Driver
                                          </span>
                                        </li>
                                      )}
                                      {(motorRequest?.isPassengerCover ||
                                        item?.PassengerCover) && (
                                        <li className='product_list'>
                                          <span>
                                            <span className='fa '>
                                              <img
                                                style={{ width: '12px' }}
                                                src={
                                                  !item.PassengerCover
                                                    ? '/assetss/images/crossed.png'
                                                    : '/assetss/images/check.png'
                                                }
                                              />
                                            </span>
                                          </span>
                                          <span className='product_text'>
                                            Passenger Cover
                                          </span>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* {apiRequestQQ?.NewPolicyType !== "ThirdParty" ? (
                                      <ul className="p-0 row  d-flex-column">
                                        {item.PersonalAccident != false && (
                                          <li className="col-lg-3 ps-lg-0 mb-1">
                                            <span>
                                              <span
                                                className={
                                                  item.PersonalAccident === false
                                                    ? "text-danger"
                                                    : "text-success"
                                                }
                                              >
                                                {" "}
                                                {item.PersonalAccident === false ? (
                                                  <span className="fa ">
                                                    <img
                                                      style={{ width: "12px" }}
                                                      src="./assetss/images/crossed.png"
                                                    />
                                                  </span>
                                                ) : (
                                                  <span>
                                                    <img
                                                      style={{ width: "12px" }}
                                                      src="./assetss/images/check.png"
                                                    />
                                                  </span>
                                                )}
                                              </span>{" "}
                                              Personal Accident
                                            </span>
                                          </li>
                                        )}
                                        {
                                          <>
                                            {filter.RoadSideAssistance === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.RoadSideAssistance === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.RoadSideAssistance === false ? (
                                                      <span className="fa ">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Road Side Assistance
                                                </span>
                                              </li>
                                            )}

                                            {filter.EngineProtection === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.EngineProtection === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.EngineProtection === false ? (
                                                      <span className="fa ">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Engine Protection
                                                </span>
                                              </li>
                                            )}

                                            {filter.TyreProtection === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.TyreProtection === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.TyreProtection === false ? (
                                                      <span className="fa ">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Tyre Protection
                                                </span>
                                              </li>
                                            )}

                                            {filter.RimProtection === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.RimProtection === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.RimProtection === false ? (
                                                      <span className="fa ">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Rim Protection
                                                </span>
                                              </li>
                                            )}

                                            {filter.Consumables === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.Consumables === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.Consumables === false ? (
                                                      <span className="fa ">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Consumables
                                                </span>
                                              </li>
                                            )}

                                            {filter.NCBProtection === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.NCBProtection === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.NCBProtection === false ? (
                                                      <span className="fa ">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  NCBProtection
                                                </span>
                                              </li>
                                            )}

                                            {filter.IsElectricalAccessories === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.IsElectricalAccessories === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.IsElectricalAccessories ===
                                                    false ? (
                                                      <span className="fa ">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Electrical Accessories
                                                </span>
                                              </li>
                                            )}

                                            {filter.IsNonElectricalAccessories === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.IsNonElectricalAccessories ===
                                                      false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.IsNonElectricalAccessories ===
                                                    false ? (
                                                      <span className="fa ">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Non Electrical Accessories
                                                </span>
                                              </li>
                                            )}

                                            {filter.InvoiceCover === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.InvoiceCover === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.InvoiceCover === false ? (
                                                      <span className="fa">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Invoice Cover
                                                </span>
                                              </li>
                                            )}

                                            {filter.EngineGearBox === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.EngineGearBox === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.EngineGearBox === false ? (
                                                      <span className="fa">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Engine GearBox
                                                </span>
                                              </li>
                                            )}

                                            {filter.VoluntaryDeductive === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.VoluntaryDeductive === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.VoluntaryDeductive === false ? (
                                                      <span className="fa ">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Voluntary Deductive
                                                </span>
                                              </li>
                                            )}

                                            {filter.PassengerCover === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.PassengerCover === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.PassengerCover === false ? (
                                                      <span className="fa ">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Passenger Cover
                                                </span>
                                              </li>
                                            )}

                                            {filter.LossOfPersonalBelongings === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.LossOfPersonalBelongings === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.LossOfPersonalBelongings ===
                                                    false ? (
                                                      <span className="fa ">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Loss Of Personal Belongings
                                                </span>
                                              </li>
                                            )}

                                            {filter.ZeroDepriciationCover === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.ZeroDepriciationCover === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.ZeroDepriciationCover === false ? (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Zero DepriciationCover
                                                </span>
                                              </li>
                                            )}

                                            {filter.KeyReplacement === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.KeyReplacement === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.KeyReplacement === false ? (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  KeyReplacement
                                                </span>
                                              </li>
                                            )}

                                            {filter.LiabilitiesToPaidDriver === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.DriverLiability === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.DriverLiability === false ? (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Liabilities To PaidDriver
                                                </span>
                                              </li>
                                            )}
                                          </>
                                        }
                                      </ul>
                                    ) : (
                                      <ul className="p-0 row  d-flex-column">
                                        {item.PersonalAccident != false && (
                                          <li className="col-lg-3 ps-lg-0 mb-1">
                                            <span>
                                              <span
                                                className={
                                                  item.PersonalAccident === false
                                                    ? "text-danger"
                                                    : "text-success"
                                                }
                                              >
                                                {" "}
                                                {item.PersonalAccident === false ? (
                                                  <span className="fa ">
                                                    <img
                                                      style={{ width: "12px" }}
                                                      src="./assetss/images/crossed.png"
                                                    />
                                                  </span>
                                                ) : (
                                                  <span>
                                                    <img
                                                      style={{ width: "12px" }}
                                                      src="./assetss/images/check.png"
                                                    />
                                                  </span>
                                                )}
                                              </span>{" "}
                                              Personal Accident
                                            </span>
                                          </li>
                                        )}
                                        {apiRequestQQ.isFiltered === true && (
                                          <>
                                            {filter.PassengerCover === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.PassengerCover === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.PassengerCover === false ? (
                                                      <span className="fa ">
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Passenger Cover
                                                </span>
                                              </li>
                                            )}
                                            {filter.LiabilitiesToPaidDriver === true && (
                                              <li className="col-lg-3 ps-lg-0 mb-1">
                                                <span>
                                                  <span
                                                    className={
                                                      item.DriverLiability === false
                                                        ? "text-danger"
                                                        : "text-success"
                                                    }
                                                  >
                                                    {" "}
                                                    {item.DriverLiability === false ? (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/crossed.png"
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{ width: "12px" }}
                                                          src="./assetss/images/check.png"
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{" "}
                                                  Liabilities To PaidDriver
                                                </span>
                                              </li>
                                            )}
                                          </>
                                        )}
                                      </ul>
                                    )} */}
                      </div>
                      {/* </div> */}
                    </div>
                    {index === QuickQouteResult.length - 1 &&
                      QuickQouteResult.length + ApiErrorArr.length < 2 && (
                        <Shimmer />
                      )}
                  </React.Fragment>
                )
              })
            ) : QuickQouteResult.length === 0 && ApiErrorArr.length === 2 ? (
              <div></div>
            ) : (
              <Shimmer />
            )}
          </section>
        </div>
      </div>
      {/* </section> */}
      {editDetails && (
        <EditVehicleDetails show={setModalEdit} setModal={setEditDetails} />
      )}
      {/* ncb modal */}
      {ncbModel && (
        <QuotelistModel
          show={ncbModel}
          setModel={setNcbModel}
          motorQuotation={motorQuotation}
          // handleFilteredResult={handleFilteredResult}
        />
      )}

      <Modal
        backdrop='static'
        isOpen={idvModal}
        toggle={toggleIdvModal}
        size='md'
        className='modal-dialog-centered'
      >
        <ModalBody>
          <div className='modal-logo'>
            <a>
              <img src='/assets/images/ex_new_2.png'></img>
            </a>
          </div>
          <div className='modal_heading w-100 mb-3'>
            <h4 className='text-center fs-1 mb-4'>
              IDV{' '}
              {motorRequest?.vehicleType === VEHICLE_TYPE.FOUR_WHEELER
                ? '( Car Value )'
                : '( Bike Value )'}
            </h4>

            <div onClick={toggleIdvModal} class='v2_close false'>
              <a>
                <img
                  src='/assets/images/delete_icon.svg'
                  title='Close'
                  alt='close'
                />
              </a>
            </div>
          </div>
          <form onSubmit={handleSubmit1(handleFilterIDV)}>
            <div>
              <div className='login_form'>
                <label class='custom-field two'>
                  <input
                    type='number'
                    maxLength={9}
                    className='form-control mb-3'
                    {...register1('idv', {
                      required: 'Field is required'
                    })}
                    // onChange={(e) => {
                    //   const newIdv = parseInt(e.target.value);
                    //   if (
                    //     newIdv >= range.minimumIdv &&
                    //     newIdv <= range.maximumIdv
                    //   ) {
                    //     setIdv(newIdv);
                    //     dispatchQuickQuote("Idv", newIdv);
                    //     dispatchMotorQuote("idv", newIdv);
                    //     setIdvError(false);
                    //   } else if (newIdv < range.minimumIdv) {
                    //     setIdv(range.minimumIdv);
                    //     dispatchQuickQuote("Idv", newIdv);
                    //     dispatchMotorQuote("idv", newIdv);
                    //     setIdvError(true);
                    //   } else {
                    //     setIdvError(true);
                    //   }
                    //   // setIdv(e?.target?.value);
                    // }}
                    onChange={e => {
                      if (
                        e.target.value >= Math.round(minMaxIdv?.minimumIdv) &&
                        e.target.value <= Math.round(minMaxIdv?.maximumIdv)
                      ) {
                        setIdvError(false)
                      } else {
                        setIdvError(true)
                      }
                    }}
                    onInput={e => {
                      e.target.value = e.target.value.slice(0, 10)
                    }}
                    placeholder=''
                  />
                  <span class='placeholder'> Choose Your Own IDV</span>
                  {idvError && (
                    <div className='text-danger'>
                      Please Enter the IDV within specified range
                    </div>
                  )}
                </label>
              </div>
              <div className='idv_block mt-3'>
                <div className='left_idv'>
                  <div
                    htmlFor='floatingSelect'
                    className='fs-4'
                    style={{ fontWeight: '600' }}
                  >
                    Select IDV Value
                  </div>
                  <div className='d-flex justify-content-between align-items-center my-2 fs-4'>
                    <small>
                      {Math.round(minMaxIdv?.minimumIdv)} {' - '}
                      {Math.round(minMaxIdv?.maximumIdv)}
                    </small>
                  </div>
                </div>
                <div className='right_btn d-flex gap-2'>
                  <button
                    href='javascript:void(0)'
                    type='submit'
                    // onClick={() => {
                    //   if (!idvError) {
                    //     handleFilterIDV(idv);
                    //     setIdvModal(false); // Close the modal after successful update
                    //     setIdvError(false);
                    //   } else {
                    //     // Display an error message when IDV is out of range
                    //     setIdvError(true);
                    //   }
                    // }}
                    className='btn'
                    style={{
                      fontSize: '15px',
                      padding: '10px 20px',
                      minWidth: 'auto'
                    }}
                  >
                    Update
                  </button>
                  <button
                    href='javascript:void(0)'
                    className='btn'
                    type='button'
                    style={{
                      fontSize: '15px',
                      padding: '10px 20px',
                      minWidth: 'auto'
                    }}
                    onClick={() => {
                      const updatedData = {
                        ...motorRequest,
                        idv: 0
                      }
                      dispatch(resetQuickQuoteResults())

                      motorQuotation(updatedData)
                      setIdvModal(false)
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      {/* Premium Breakup */}
      <Modal
        backdrop='static'
        isOpen={modal2}
        toggle={toggleModal2}
        centered
        size='lg'
      >
        <ModalBody>
          <div className='modal_heading w-100 mb-3'>
            <h4 className='text-center fs-1 mb-4'> Plan Detail</h4>

            <div onClick={toggleModal2} class='v2_close false'>
              <a>
                <img
                  src='/assets/images/delete_icon.svg'
                  title='Close'
                  alt='close'
                />
              </a>
            </div>
          </div>
          <div className='plan_modal'>
            <img
              src={planDetail?.Logo}
              className='nvimg'
              alt=''
              style={{ width: '110px' }}
            />
            <h1 className='text-center my-lg-0'>
              {planDetail?.insurer}
              <p
                className='fs-8 text-muted  mt-lg-0 mt-3'
                style={{ color: '#01b0cb' }}
              >
                ({planDetail?.policyType})
              </p>
            </h1>

            <a
              href='javascript:void(0)'
              onClick={() => selectUserPlan(planDetail)}
              className='btn'
              style={{
                fontWeight: 500,
                border: 'none',
                background: '#01b0cb',
                color: 'white'
              }}
            >
              BUY NOW
            </a>
          </div>

          <div
            className='row mx-0 p-4  mt-2 justify-content-between'
            style={{ background: '#01b0cb' }}
          >
            <div className='col-lg-4 col-6 ps-lg-0'>
              <small className='fs-3'>Cover Value (IDV)</small>
              <p className='fs-4'>
                {motorRequest?.newPolicyType !== POLICY_TYPE.THIRDPARTY ? (
                  <>
                    {/* {modelIdv?.idv ? (
                      Math.round(modelIdv.idv)
                    ) : (
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    )} */}
                    {motorRequest?.idv}
                  </>
                ) : (
                  <small className='col-5 fs-4'>
                    <strong>Cover: </strong>Third Party
                  </small>
                )}
              </p>
            </div>

            <div className='col-lg-4 col-6text-center d-none'>
              <small className='fs-3'>Tenure</small>
              <p className='fw-bold fs-4'>1 year OD + 3 year TP</p>
            </div>

            <div className='col-lg-4 col-6 ps-lg-0 pe-lg-0 text-end'>
              <small className='fs-3'>Policy Start Date</small>
              <p className='fw-bold fs-4'>
                {moment(planDetail3?.StartDate, [
                  'YYYY-MM-DD',
                  'DD/MM/YYYY'
                ]).format('DD MMM, YYYY')}
              </p>
            </div>
          </div>

          <div className='row mx-0 p-1  mt-5'>
            <div className='col-12 px-0'>
              <h1 className='fs-1'>Premium Breakup</h1>
            </div>
            <div className='col-lg-12 px-0'>
              <small
                className='fw-bold fs-2  mt-3 d-block  mb-1'
                style={{ color: '#01b0cb' }}
              >
                Basic Covers
              </small>
            </div>

            <div className='col-lg-12 px-0'>
              <table className='table  fs-4 mt-3 '>
                <tbody>
                  <tr>
                    <th>Basic Own Damage</th>

                    <td style={{ textAlign: 'end' }}>
                      {typeof planDetail?.BasicODPremium === 'number'
                        ? Math.round(
                            planDetail?.BasicODPremium.toString().replace(
                              'INR',
                              ''
                            )
                          )
                        : typeof planDetail?.BasicODPremium === 'string'
                        ? Math.round(
                            planDetail?.BasicODPremium.replace('INR', '')
                          )
                        : Math.round(planDetail?.BasicODPremium)}{' '}
                    </td>
                  </tr>
                  <tr>
                    <th>Basic Third Party Premium</th>

                    <td style={{ textAlign: 'end' }}>
                      {typeof planDetail?.BasicTPPremium === 'number'
                        ? Math.round(
                            planDetail?.BasicTPPremium.toString().replace(
                              'INR',
                              ''
                            )
                          )
                        : typeof planDetail?.BasicTPPremium === 'string'
                        ? Math.round(
                            planDetail?.BasicTPPremium.replace('INR', '')
                          )
                        : Math.round(planDetail?.BasicTPPremium)}
                    </td>
                  </tr>
                  {planDetail?.IsElectricalAccessories != false && (
                    <tr>
                      <th>Electrical Accessories Premium</th>

                      <td style={{ textAlign: 'end' }}>
                        {planDetail?.Api_name !== 'digit'
                          ? typeof planDetail?.IsElectricalAccessories ===
                            'number'
                            ? Math.round(
                                planDetail?.IsElectricalAccessories.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail?.IsElectricalAccessories ===
                              'string'
                            ? Math.round(
                                planDetail?.IsElectricalAccessories.replace(
                                  'INR',
                                  ''
                                )
                              )
                            : Math.round(planDetail?.IsElectricalAccessories)
                          : 'Included'}
                      </td>
                    </tr>
                  )}
                  {planDetail?.IsNonElectricalAccessories != false && (
                    <tr>
                      <th>Non Electrical Accessories Premium</th>

                      <td style={{ textAlign: 'end' }}>
                        {planDetail?.Api_name !== 'digit'
                          ? typeof planDetail?.IsNonElectricalAccessories ===
                            'number'
                            ? Math.round(
                                planDetail?.IsNonElectricalAccessories.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail?.IsNonElectricalAccessories ===
                              'string'
                            ? Math.round(
                                planDetail?.IsNonElectricalAccessories.replace(
                                  'INR',
                                  ''
                                )
                              )
                            : Math.round(planDetail?.IsNonElectricalAccessories)
                          : 'Included'}
                      </td>
                    </tr>
                  )}
                  {planDetail?.CNGValue != false && (
                    <tr>
                      <th>Fuel Kit TP</th>

                      <td style={{ textAlign: 'end' }}>
                        {planDetail?.Api_name !== 'digit'
                          ? !isNaN(planDetail?.CNGValue) &&
                            typeof planDetail?.CNGValue === 'number'
                            ? Math.round(
                                planDetail?.CNGValue.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail?.CNGValue === 'string'
                            ? Math.round(
                                planDetail?.CNGValue.replace('INR', '')
                              )
                            : Math.round(planDetail?.CNGValue)
                          : 'Included'}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className='col-lg-12 px-0'>
              <small
                className='fw-bold fs-2  mt-3 d-block mb-1'
                style={{ color: '#01b0cb' }}
              >
                Add-on Covers
              </small>
            </div>

            <div className='col-lg-12 px-0'>
              <table className='table fs-4 mt-3' style={{ fontSize: '12px' }}>
                <tbody>
                  {planDetail?.RoadSideAssistance != false && (
                    <tr>
                      <th>Road Side Assistance</th>

                      <td style={{ textAlign: 'end' }}>
                        {typeof planDetail?.RoadSideAssistance === 'number'
                          ? Math.round(
                              planDetail?.RoadSideAssistance.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          : typeof planDetail?.RoadSideAssistance === 'string'
                          ? Math.round(
                              planDetail?.RoadSideAssistance.replace('INR', '')
                            )
                          : Math.round(planDetail?.RoadSideAssistance)}
                        {Math.round(planDetail?.IsNonElectricalAccessories)}
                      </td>
                    </tr>
                  )}
                  {planDetail?.EngineProtection != false && (
                    <tr>
                      <th>Engine Protection</th>

                      <td style={{ textAlign: 'end' }}>
                        {' '}
                        {typeof planDetail?.EngineProtection === 'number'
                          ? Math.round(
                              planDetail?.EngineProtection.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          : typeof planDetail?.EngineProtection === 'string'
                          ? Math.round(
                              planDetail?.EngineProtection.replace('INR', '')
                            )
                          : Math.round(planDetail?.EngineProtection)}
                      </td>
                    </tr>
                  )}
                  {planDetail?.TyreProtection != false && (
                    <tr>
                      <th>Tyre Protection </th>

                      <td style={{ textAlign: 'end' }}>
                        {typeof planDetail?.TyreProtection === 'number'
                          ? Math.round(
                              planDetail?.TyreProtection.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          : typeof planDetail?.TyreProtection === 'string'
                          ? Math.round(
                              planDetail?.TyreProtection.replace('INR', '')
                            )
                          : Math.round(planDetail?.TyreProtection)}
                      </td>
                    </tr>
                  )}
                  {planDetail?.RimProtection != false && (
                    <tr>
                      <th>Rim Protection </th>

                      <td style={{ textAlign: 'end' }}>
                        {typeof planDetail?.RimProtection === 'number'
                          ? Math.round(
                              planDetail?.RimProtection.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          : typeof planDetail?.RimProtection === 'string'
                          ? Math.round(
                              planDetail?.RimProtection.replace('INR', '')
                            )
                          : Math.round(planDetail?.RimProtection)}
                      </td>
                    </tr>
                  )}
                  {planDetail?.Consumables != false && (
                    <tr>
                      <th>Consumables </th>

                      <td style={{ textAlign: 'end' }}>
                        {typeof planDetail?.Consumables === 'number'
                          ? Math.round(
                              planDetail?.Consumables.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          : typeof planDetail?.Consumables === 'string'
                          ? Math.round(
                              planDetail?.Consumables.replace('INR', '')
                            )
                          : Math.round(planDetail?.Consumables)}
                      </td>
                    </tr>
                  )}
                  {planDetail?.PersonalAccident != false && (
                    <tr>
                      <th>Personal Accident </th>

                      <td style={{ textAlign: 'end' }}>
                        {typeof planDetail?.PersonalAccident === 'number'
                          ? Math.round(
                              planDetail?.PersonalAccident.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          : typeof planDetail?.PersonalAccident === 'string'
                          ? Math.round(
                              planDetail?.PersonalAccident.replace('INR', '')
                            )
                          : Math.round(planDetail?.PersonalAccident)}
                      </td>
                    </tr>
                  )}
                  {planDetail?.InvoiceCover != false && (
                    <tr>
                      <th>Invoice Cover </th>

                      <td style={{ textAlign: 'end' }}>
                        {typeof planDetail?.InvoiceCover === 'number'
                          ? Math.round(
                              planDetail?.InvoiceCover.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          : typeof planDetail?.InvoiceCover === 'string'
                          ? Math.round(
                              planDetail?.InvoiceCover.replace('INR', '')
                            )
                          : Math.round(planDetail?.InvoiceCover)}
                      </td>
                    </tr>
                  )}
                  {/* {planDetail?.EngineGearBox != false && (
                    <tr>
                      <th>Engine GearBox </th>

                      <td style={{ textAlign: "end" }}>
                        {typeof planDetail?.EngineGearBox === "number"
                          ? Math.round(
                              planDetail?.EngineGearBox.toString().replace(
                                "INR",
                                ""
                              )
                            )
                          : typeof planDetail?.EngineGearBox === "string"
                          ? Math.round(
                              planDetail?.EngineGearBox.replace("INR", "")
                            )
                          : Math.round(planDetail?.EngineGearBox)}
                      </td>
                    </tr>
                  )} */}
                  {planDetail?.PassengerCover != false && (
                    <tr>
                      <th>Passenger Cover </th>

                      <td style={{ textAlign: 'end' }}>
                        {typeof planDetail?.PassengerCover === 'number'
                          ? Math.round(
                              planDetail?.PassengerCover.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          : typeof planDetail?.PassengerCover === 'string'
                          ? Math.round(
                              planDetail?.PassengerCover.replace('INR', '')
                            )
                          : Math.round(planDetail?.PassengerCover)}
                      </td>
                    </tr>
                  )}
                  {planDetail?.LossOfPersonalBelongings != false && (
                    <tr>
                      <th>Loss Of PersonalBelongings </th>

                      <td style={{ textAlign: 'end' }}>
                        {typeof planDetail?.LossOfPersonalBelongings ===
                        'number'
                          ? Math.round(
                              planDetail?.LossOfPersonalBelongings.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          : typeof planDetail?.LossOfPersonalBelongings ===
                            'string'
                          ? Math.round(
                              planDetail?.LossOfPersonalBelongings.replace(
                                'INR',
                                ''
                              )
                            )
                          : Math.round(planDetail?.LossOfPersonalBelongings)}
                      </td>
                    </tr>
                  )}

                  {planDetail?.ZeroDepriciationCover != false && (
                    <tr>
                      <th>Zero DepriciationCover </th>

                      <td style={{ textAlign: 'end' }}>
                        {typeof planDetail?.ZeroDepriciationCover === 'number'
                          ? Math.round(
                              planDetail?.ZeroDepriciationCover.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          : typeof planDetail?.ZeroDepriciationCover ===
                            'string'
                          ? Math.round(
                              planDetail?.ZeroDepriciationCover.replace(
                                'INR',
                                ''
                              )
                            )
                          : Math.round(planDetail?.ZeroDepriciationCover)}
                      </td>
                    </tr>
                  )}

                  {planDetail?.KeyReplacement != false && (
                    <tr>
                      <th>KeyReplacement </th>

                      <td style={{ textAlign: 'end' }}>
                        {typeof planDetail?.KeyReplacement === 'number'
                          ? Math.round(
                              planDetail?.KeyReplacement.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          : typeof planDetail?.KeyReplacement === 'string'
                          ? Math.round(
                              planDetail?.KeyReplacement.replace('INR', '')
                            )
                          : Math.round(planDetail?.KeyReplacement)}
                      </td>
                    </tr>
                  )}
                  {planDetail?.NCBProtection != false && (
                    <tr>
                      <th>NCBProtection </th>
                      <td style={{ textAlign: 'end' }}>
                        {planDetail?.NCBProtection === true ? (
                          <span>(Included)</span>
                        ) : (
                          Math.round(
                            planDetail?.NCBProtection.toString().replace(
                              'INR',
                              ''
                            )
                          )
                        )}
                      </td>
                    </tr>
                  )}
                  {/* {planDetail?Api_name !== 'Tata AIG General Insurance Co. Ltd.'  ? (planDetail?.DriverLiability != false && (
                    <tr>
                      <th>Liabilities TO Paid Driver </th>
                      <td style={{ textAlign: "end" }}>
                        {planDetail?.DriverLiability === true ? (
                          <span>(Included)</span>
                        ) : (
                          planDetail?.DriverLiability
                        )}
                      </td>
                    </tr>
                  )}
                  {/* {!isNaN(planDetail?.NCBProtection) &&
                    planDetail?.NCBProtection != false && (
                      <tr>
                        <th>Liabilities TO Paid Driver </th>
                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail?.NCBProtection === 'number'
                            ? Math.round(
                                planDetail?.NCBProtection.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail?.NCBProtection === 'string'
                            ? Math.round(
                                planDetail?.NCBProtection.replace('INR', '')
                              )
                            : Math.round(planDetail?.NCBProtection)}
                        </td>
                      </tr>
                    )} */}
                  {/* {!isNaN(planDetail?.DriverLiability) &&
                    planDetail?.DriverLiability != false && (
                      <tr>
                        <th>Liabilities TO Paid Driver </th>
                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail?.DriverLiability === 'number'
                            ? Math.round(
                                planDetail?.DriverLiability.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail?.DriverLiability === 'string'
                            ? Math.round(
                                planDetail?.DriverLiability.replace('INR', '')
                              )
                            : Math.round(planDetail?.DriverLiability)}
                        </td>
                      </tr>
                    )} */}
                </tbody>
              </table>
            </div>

            <div className='col-lg-12 px-0'>
              <small
                className='fw-bold fs-2  mt-1 d-block mb-1'
                style={{ color: '#01b0cb' }}
              >
                Discounts
              </small>
            </div>
            <div className='col-lg-12 px-0'>
              <table className='table  fs-4 mt-3' style={{ fontSize: '12px' }}>
                <tbody>
                  {planDetail?.VoluntaryDeductive != false && (
                    <tr>
                      <th>Voluntary Deductive </th>

                      <td style={{ textAlign: 'end' }}>
                        {planDetail?.VoluntaryDeductive === true ? (
                          <span>(Included)</span>
                        ) : typeof planDetail?.VoluntaryDeductive ===
                          'number' ? (
                          Math.round(
                            planDetail?.VoluntaryDeductive.toString().replace(
                              'INR',
                              ''
                            )
                          )
                        ) : typeof planDetail?.VoluntaryDeductive ===
                          'string' ? (
                          Math.round(
                            planDetail?.VoluntaryDeductive.replace('INR', '')
                          )
                        ) : (
                          planDetail?.VoluntaryDeductive
                        )}
                      </td>
                    </tr>
                  )}
                  {planDetail?.NCBDiscount != false && (
                    <tr>
                      <th>
                        NCB Discount ({apiRequestQQ?.CurrentNoClaimBonus}%)
                      </th>

                      <td style={{ textAlign: 'end' }}>
                        {' '}
                        {planDetail?.NCBDiscount === true ? (
                          <span>(Included)</span>
                        ) : typeof planDetail?.NCBDiscount === 'number' ? (
                          Math.round(
                            planDetail?.NCBDiscount.toString().replace(
                              'INR',
                              ''
                            )
                          )
                        ) : typeof planDetail?.NCBDiscount === 'string' ? (
                          Math.round(planDetail?.NCBDiscount.replace('INR', ''))
                        ) : (
                          planDetail?.NCBDiscount
                        )}
                      </td>
                    </tr>
                  )}

                  {planDetail?.discount != false && (
                    <tr>
                      <th>OD Discount </th>

                      <td style={{ textAlign: 'end' }}>
                        {' '}
                        {planDetail?.discount === true ? (
                          <span>(Included)</span>
                        ) : typeof planDetail?.discount === 'number' ? (
                          Math.round(
                            planDetail?.discount.toString().replace('INR', '')
                          )
                        ) : typeof planDetail?.discount === 'string' ? (
                          Math.round(planDetail?.discount.replace('INR', ''))
                        ) : (
                          planDetail?.discount || '0'
                        )}
                      </td>
                    </tr>
                  )}
                  {planDetail?.Api_name === 'Magma' &&
                    planDetail?.ElectricAmount != false && (
                      <tr>
                        <th>Elecrical-Detariff Discount </th>

                        <td style={{ textAlign: 'end' }}>
                          {' '}
                          {planDetail?.ElectricAmount === true ? (
                            <span>(Included)</span>
                          ) : typeof planDetail?.ElectricAmount === 'number' ? (
                            Math.round(
                              planDetail?.ElectricAmount.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          ) : typeof planDetail?.ElectricAmount === 'string' ? (
                            Math.round(
                              planDetail?.ElectricAmount.replace('INR', '')
                            )
                          ) : (
                            planDetail?.ElectricAmount || '0'
                          )}
                        </td>
                      </tr>
                    )}
                  {planDetail?.Api_name === 'Magma' &&
                    planDetail?.nonElectricalAmount != false && (
                      <tr>
                        <th>NonElecrical-Detariff Discount </th>

                        <td style={{ textAlign: 'end' }}>
                          {' '}
                          {planDetail?.nonElectricalAmount === true ? (
                            <span>(Included)</span>
                          ) : typeof planDetail?.nonElectricalAmount ===
                            'number' ? (
                            Math.round(
                              planDetail?.nonElectricalAmount
                                .toString()
                                .replace('INR', '')
                            )
                          ) : typeof planDetail?.nonElectricalAmount ===
                            'string' ? (
                            Math.round(
                              planDetail?.nonElectricalAmount.replace('INR', '')
                            )
                          ) : (
                            planDetail?.nonElectricalAmount || '0'
                          )}
                        </td>
                      </tr>
                    )}
                  {planDetail?.Api_name === 'Magma' &&
                    planDetail?.CNGDiscount != false && (
                      <tr>
                        <th>
                          {apiRequestQQ.FuelType === 'CNG'
                            ? 'Inbuilt CNGkit-Detariff Discount'
                            : 'ExternalCNGkit-Detariff Discount'}
                        </th>

                        <td style={{ textAlign: 'end' }}>
                          {' '}
                          {planDetail?.CNGDiscount === true ? (
                            <span>(Included)</span>
                          ) : typeof planDetail?.CNGDiscount === 'number' ? (
                            Math.round(
                              planDetail?.CNGDiscount.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          ) : typeof planDetail?.CNGDiscount === 'string' ? (
                            Math.round(
                              planDetail?.CNGDiscount.replace('INR', '')
                            )
                          ) : (
                            planDetail?.CNGDiscount || '0'
                          )}
                        </td>
                      </tr>
                    )}
                  {planDetail?.NetPremium != false && (
                    <tr>
                      <th>Net Premium</th>

                      <td style={{ textAlign: 'end' }}>
                        {' '}
                        {planDetail?.NetPremium === true ? (
                          <span>(Included)</span>
                        ) : typeof planDetail?.NetPremium === 'number' ? (
                          Math.round(
                            planDetail?.NetPremium.toString().replace('INR', '')
                          )
                        ) : typeof planDetail?.NetPremium === 'string' ? (
                          Math.round(planDetail?.NetPremium.replace('INR', ''))
                        ) : (
                          planDetail?.NetPremium || '0'
                        )}
                      </td>
                    </tr>
                  )}
                  {planDetail?.GST != false && (
                    <tr>
                      <th>GST (18%) </th>

                      <td style={{ textAlign: 'end' }}>
                        {' '}
                        {planDetail?.GST === true ? (
                          <span>(Included)</span>
                        ) : typeof planDetail?.GST === 'number' ? (
                          Math.round(
                            planDetail?.GST.toString().replace('INR', '')
                          )
                        ) : typeof planDetail?.GST === 'string' ? (
                          Math.round(planDetail?.GST.replace('INR', ''))
                        ) : (
                          planDetail?.GST
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className='col-12 px-0'>
              <div className='card mt-5'>
                <div className='card-footer border-0 d-flex justify-content-between'>
                  <h2 className='mb-0 fs-3'>Total Payble</h2>
                  <p className='mb-0 fs-3'>
                    {' '}
                    {planDetail?.FinalPremium === true ? (
                      <span>(Included)</span>
                    ) : typeof planDetail?.FinalPremium === 'number' ? (
                      Math.round(
                        planDetail?.FinalPremium.toString().replace('INR', '')
                      )
                    ) : typeof planDetail?.FinalPremium === 'string' ? (
                      Math.round(planDetail?.FinalPremium.replace('INR', ''))
                    ) : (
                      planDetail?.FinalPremium
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {planDetail3 !== null && (
        <Modal
          backdrop='static'
          isOpen={modal3}
          toggle={toggleModal3}
          centered
          size='lg'
        >
          <ModalBody>
            <div className='modal_heading w-100 mb-3'>
              <h4 className='text-center fs-1 mb-4'> Plan Detail</h4>

              <div onClick={toggleModal3} class='v2_close false'>
                <a>
                  <img
                    src='/assets/images/delete_icon.svg'
                    title='Close'
                    alt='close'
                  />
                </a>
              </div>
            </div>
            <div className='plan_modal'>
              <img
                src={planDetail3?.Logo}
                className='nvimg'
                alt=''
                style={{ width: '110px' }}
              />
              <h1 className='text-center my-lg-0'>
                {planDetail3?.insurer}
                <p
                  className='fs-8 text-muted  mt-lg-0 mt-3'
                  style={{ color: '#01b0cb' }}
                >
                  ({planDetail3?.policyType})
                </p>
              </h1>

              <a
                href='javascript:void(0)'
                onClick={() => selectUserPlan(planDetail3)}
                className='btn'
                style={{
                  fontWeight: 500,
                  border: 'none',
                  background: '#01b0cb',
                  color: 'white'
                }}
              >
                BUY NOW
              </a>
            </div>

            <div
              className='row mx-0 p-4  mt-2 justify-content-between'
              style={{ background: '#01b0cb' }}
            >
              <div className='col-lg-4 col-6 ps-lg-0'>
                <small className='fs-3'>Cover Value (IDV)</small>
                <p className='fs-4'>
                  {planDetail?.policyType !== 'thirdParty' ? (
                    <>
                      {modelIdv?.idv ? (
                        Math.round(modelIdv.idv)
                      ) : (
                        <div class='spinner-border' role='status'>
                          <span class='sr-only'>Loading...</span>
                        </div>
                      )}
                    </>
                  ) : (
                    <small className='col-5 fs-4'>
                      <strong>Cover: </strong>Third Party
                    </small>
                  )}
                </p>
              </div>

              <div className='col-lg-4 col-6text-center d-none'>
                <small className='fs-3'>Tenure</small>
                <p className='fw-bold fs-4'>1 year OD + 3 year TP</p>
              </div>

              <div className='col-lg-4 col-6 ps-lg-0 pe-lg-0 text-end'>
                <small className='fs-3'>Policy Start Date</small>
                <p className='fw-bold fs-4'>
                  {moment(planDetail3?.StartDate, [
                    'YYYY-MM-DD',
                    'DD/MM/YYYY'
                  ]).format('DD MMM, YYYY')}
                </p>
              </div>
            </div>

            <div className='row mx-0 p-1  mt-5'>
              <div className='col-12 px-0'>
                <h1 className='fs-1'>Premium Breakup</h1>
              </div>
              <div className='col-lg-12 px-0'>
                <small
                  className='fw-bold fs-2  mt-3 d-block  mb-1'
                  style={{ color: '#01b0cb' }}
                >
                  Basic Covers
                </small>
              </div>

              <div className='col-lg-12 px-0'>
                <table className='table  fs-4 mt-3 '>
                  <tbody>
                    <tr>
                      <th>Basic Own Damage</th>

                      <td style={{ textAlign: 'end' }}>
                        {planDetail3?.BasicODPremium !== '' &&
                          Math.round(planDetail3?.BasicODPremium)}{' '}
                      </td>
                    </tr>
                    <tr>
                      <th>Basic Third Party Premium</th>

                      <td style={{ textAlign: 'end' }}>
                        {planDetail3?.BasicTPPremium !== '' &&
                          Math.round(planDetail3.BasicTPPremium)}
                      </td>
                    </tr>
                    {planDetail3?.IsElectricalAccessories != false && (
                      <tr>
                        <th>Electrical Accessories Premium</th>

                        <td style={{ textAlign: 'end' }}>
                          {Math.round(planDetail3?.ElectricAmount)}
                        </td>
                      </tr>
                    )}
                    {planDetail?.IsNonElectricalAccessories != false && (
                      <tr>
                        <th>Non Electrical Accessories Premium</th>

                        <td style={{ textAlign: 'end' }}>
                          {Math.round(planDetail3.nonElectricalAmount)}
                        </td>
                      </tr>
                    )}
                    {/* {planDetail?.CNGValue != false && (
                    <tr>
                      <th>Fuel Kit TP</th>

                      <td style={{ textAlign: "end" }}>
                        {planDetail?.Api_name !== "digit"  ? (!isNaN(planDetail?.CNGValue) &&
                        typeof planDetail?.CNGValue === "number"
                          ? Math.round(
                              planDetail?.CNGValue.toString().replace("INR", "")
                            )
                          : typeof planDetail?.CNGValue === "string"
                          ? Math.round(planDetail?.CNGValue.replace("INR", ""))
                          : Math.round(planDetail?.CNGValue)) : 'Included'}
                      </td>
                    </tr>
                  )} */}
                  </tbody>
                </table>
              </div>

              <div className='col-lg-12 px-0'>
                <small
                  className='fw-bold fs-2  mt-3 d-block mb-1'
                  style={{ color: '#01b0cb' }}
                >
                  Add-on Covers
                </small>
              </div>

              <div className='col-lg-12 px-0'>
                <table className='table fs-4 mt-3' style={{ fontSize: '12px' }}>
                  <tbody>
                    {planDetail3?.RoadSideAssistance != false && (
                      <tr>
                        <th>Road Side Assistance</th>

                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail3?.RoadSideAssistanceAmount ===
                          'number'
                            ? planDetail3?.RoadSideAssistanceAmount == 0
                              ? 'Free'
                              : Math.round(
                                  planDetail3?.RoadSideAssistanceAmount.toString().replace(
                                    'INR',
                                    ''
                                  )
                                )
                            : typeof planDetail3?.RoadSideAssistanceAmount ===
                              'string'
                            ? Math.round(
                                planDetail3?.RoadSideAssistanceAmount.replace(
                                  'INR',
                                  ''
                                )
                              )
                            : Math.round(planDetail3?.RoadSideAssistanceAmount)}
                        </td>
                      </tr>
                    )}
                    {planDetail3?.EngineProtection != false && (
                      <tr>
                        <th>Engine Protection</th>

                        <td style={{ textAlign: 'end' }}>
                          {' '}
                          {typeof planDetail3?.EngineProtectionAmount ===
                          'number'
                            ? Math.round(
                                planDetail3?.EngineProtectionAmount.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail3?.EngineProtectionAmount ===
                              'string'
                            ? Math.round(
                                planDetail3?.EngineProtectionAmount.replace(
                                  'INR',
                                  ''
                                )
                              )
                            : Math.round(planDetail3?.EngineProtectionAmount)}
                        </td>
                      </tr>
                    )}
                    {planDetail3?.TyreProtection != false && (
                      <tr>
                        <th>Tyre Protection </th>

                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail3?.TyreProtectionAmount === 'number'
                            ? Math.round(
                                planDetail3?.TyreProtectionAmount.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail3?.TyreProtectionAmount ===
                              'string'
                            ? Math.round(
                                planDetail3?.TyreProtectionAmount.replace(
                                  'INR',
                                  ''
                                )
                              )
                            : Math.round(planDetail3?.TyreProtectionAmount)}
                        </td>
                      </tr>
                    )}
                    {planDetail3?.RimProtection != false && (
                      <tr>
                        <th>Rim Protection </th>

                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail3?.RimProtectionAmount === 'number'
                            ? Math.round(
                                planDetail3?.RimProtectionAmount.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail3?.RimProtectionAmount ===
                              'string'
                            ? Math.round(
                                planDetail3?.RimProtectionAmount.replace(
                                  'INR',
                                  ''
                                )
                              )
                            : Math.round(planDetail3?.RimProtectionAmount)}
                        </td>
                      </tr>
                    )}
                    {planDetail3?.Consumables != false && (
                      <tr>
                        <th>Consumables </th>

                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail3?.ConsumablesAmount === 'number'
                            ? Math.round(
                                planDetail3?.ConsumablesAmount.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail3?.ConsumablesAmount === 'string'
                            ? Math.round(
                                planDetail3?.ConsumablesAmount.replace(
                                  'INR',
                                  ''
                                )
                              )
                            : Math.round(planDetail3?.ConsumablesAmount)}
                        </td>
                      </tr>
                    )}
                    {planDetail3?.PersonalAccident != false && (
                      <tr>
                        <th>Personal Accident </th>

                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail3?.PaCoverAmount === 'number'
                            ? Math.round(
                                planDetail3?.PaCoverAmount.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail3?.PaCoverAmount === 'string'
                            ? Math.round(
                                planDetail3?.PaCoverAmount.replace('INR', '')
                              )
                            : Math.round(planDetail3?.PaCoverAmount)}
                        </td>
                      </tr>
                    )}
                    {planDetail3?.InvoiceCover != false && (
                      <tr>
                        <th>Invoice Cover </th>

                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail3?.InvoiceCoverAmount === 'number'
                            ? Math.round(
                                planDetail3?.InvoiceCoverAmount.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail3?.InvoiceCoverAmount ===
                              'string'
                            ? Math.round(
                                planDetail3?.InvoiceCoverAmount.replace(
                                  'INR',
                                  ''
                                )
                              )
                            : Math.round(planDetail3?.InvoiceCoverAmount)}
                        </td>
                      </tr>
                    )}
                    {/* {planDetail?.EngineGearBox != false && (
                    <tr>
                      <th>Engine GearBox </th>

                      <td style={{ textAlign: "end" }}>
                        {typeof planDetail?.EngineGearBox === "number"
                          ? Math.round(
                              planDetail?.EngineGearBox.toString().replace(
                                "INR",
                                ""
                              )
                            )
                          : typeof planDetail?.EngineGearBox === "string"
                          ? Math.round(
                              planDetail?.EngineGearBox.replace("INR", "")
                            )
                          : Math.round(planDetail?.EngineGearBox)}
                      </td>
                    </tr>
                  )} */}
                    {planDetail3?.PassengerCover != false && (
                      <tr>
                        <th>Passenger Cover </th>

                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail3?.PassengerCoverAmount === 'number'
                            ? Math.round(
                                planDetail3?.PassengerCoverAmount.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail3?.PassengerCoverAmount ===
                              'string'
                            ? Math.round(
                                planDetail3?.PassengerCoverAmount.replace(
                                  'INR',
                                  ''
                                )
                              )
                            : Math.round(planDetail3?.PassengerCoverAmount)}
                        </td>
                      </tr>
                    )}
                    {planDetail3?.LossOfPersonalBelongings != false && (
                      <tr>
                        <th>Loss Of PersonalBelongings </th>

                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail3?.LossOfPersonalBelongingsAmount ===
                          'number'
                            ? Math.round(
                                planDetail3?.LossOfPersonalBelongingsAmount.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail3?.LossOfPersonalBelongingsAmount ===
                              'string'
                            ? Math.round(
                                planDetail3?.LossOfPersonalBelongingsAmount.replace(
                                  'INR',
                                  ''
                                )
                              )
                            : Math.round(
                                planDetail3?.LossOfPersonalBelongingsAmount
                              )}
                        </td>
                      </tr>
                    )}

                    {planDetail3?.ZeroDepriciationCover != false && (
                      <tr>
                        <th>Zero DepriciationCover </th>

                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail3?.ZeroDepriciationCoverAmount ===
                          'number'
                            ? Math.round(
                                planDetail3?.ZeroDepriciationCoverAmount.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail3?.ZeroDepriciationCoverAmount ===
                              'string'
                            ? Math.round(
                                planDetail3?.ZeroDepriciationCoverAmount.replace(
                                  'INR',
                                  ''
                                )
                              )
                            : Math.round(
                                planDetail3?.ZeroDepriciationCoverAmount
                              )}
                        </td>
                      </tr>
                    )}

                    {planDetail3?.KeyReplacement != false && (
                      <tr>
                        <th>KeyReplacement </th>

                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail3?.KeyReplacementAmount === 'number'
                            ? Math.round(
                                planDetail3?.KeyReplacementAmount.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail3?.KeyReplacementAmount ===
                              'string'
                            ? Math.round(
                                planDetail3?.KeyReplacementAmount.replace(
                                  'INR',
                                  ''
                                )
                              )
                            : Math.round(planDetail3?.KeyReplacementAmount)}
                        </td>
                      </tr>
                    )}
                    {planDetail3?.NCBProtection != false && (
                      <tr>
                        <th>NCBProtection </th>
                        <td style={{ textAlign: 'end' }}>
                          {planDetail3?.NCBProtectionAmount === true ? (
                            <span>(Included)</span>
                          ) : (
                            Math.round(
                              planDetail3?.NCBProtectionAmount.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          )}
                        </td>
                      </tr>
                    )}
                    {planDetail3?.liabilitiesToPaidDriver != false && (
                      <tr>
                        <th>Liabilities TO Paid Driver </th>

                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail3?.liabilitiesToPaidDriverAmount ===
                          'number'
                            ? Math.round(
                                planDetail3?.liabilitiesToPaidDriverAmount
                                  .toString()
                                  .replace('INR', '')
                              )
                            : typeof planDetail3?.liabilitiesToPaidDriverAmount ===
                              'string'
                            ? Math.round(
                                planDetail3?.liabilitiesToPaidDriverAmount.replace(
                                  'INR',
                                  ''
                                )
                              )
                            : Math.round(
                                planDetail3?.liabilitiesToPaidDriverAmount
                              )}
                        </td>
                      </tr>
                    )}
                    {/* {planDetail?Api_name !== 'Tata AIG General Insurance Co. Ltd.'  ? (planDetail?.DriverLiability != false && (
                    <tr>
                      <th>Liabilities TO Paid Driver </th>
                      <td style={{ textAlign: "end" }}>
                        {planDetail?.DriverLiability === true ? (
                          <span>(Included)</span>
                        ) : (
                          planDetail?.DriverLiability
                        )}
                      </td>
                    </tr>
                  )}
                  {/* {!isNaN(planDetail?.NCBProtection) &&
                    planDetail?.NCBProtection != false && (
                      <tr>
                        <th>Liabilities TO Paid Driver </th>
                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail?.NCBProtection === 'number'
                            ? Math.round(
                                planDetail?.NCBProtection.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail?.NCBProtection === 'string'
                            ? Math.round(
                                planDetail?.NCBProtection.replace('INR', '')
                              )
                            : Math.round(planDetail?.NCBProtection)}
                        </td>
                      </tr>
                    )} */}
                    {/* {!isNaN(planDetail?.DriverLiability) &&
                    planDetail?.DriverLiability != false && (
                      <tr>
                        <th>Liabilities TO Paid Driver </th>
                        <td style={{ textAlign: 'end' }}>
                          {typeof planDetail?.DriverLiability === 'number'
                            ? Math.round(
                                planDetail?.DriverLiability.toString().replace(
                                  'INR',
                                  ''
                                )
                              )
                            : typeof planDetail?.DriverLiability === 'string'
                            ? Math.round(
                                planDetail?.DriverLiability.replace('INR', '')
                              )
                            : Math.round(planDetail?.DriverLiability)}
                        </td>
                      </tr>
                    )} */}
                  </tbody>
                </table>
              </div>

              <div className='col-lg-12 px-0'>
                <small
                  className='fw-bold fs-2  mt-1 d-block mb-1'
                  style={{ color: '#01b0cb' }}
                >
                  Discounts
                </small>
              </div>
              <div className='col-lg-12 px-0'>
                <table
                  className='table  fs-4 mt-3'
                  style={{ fontSize: '12px' }}
                >
                  <tbody>
                    {planDetail3?.VoluntaryDeductive != false && (
                      <tr>
                        <th>Voluntary Deductive </th>

                        <td style={{ textAlign: 'end' }}>
                          {Math.round(planDetail3?.VoluntaryAmount)}
                        </td>
                      </tr>
                    )}
                    {motorRequest.businessType !== 'new' && (
                      <tr>
                        <th>
                          NCB Discount ({planDetail3?.ncbDiscountPercentage}%)
                        </th>

                        <td style={{ textAlign: 'end' }}>
                          {' '}
                          {Math.round(planDetail3?.NCBDiscount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.discount != false && (
                      <tr>
                        <th>OD Discount </th>

                        <td style={{ textAlign: 'end' }}>
                          {' '}
                          {planDetail?.discount === true ? (
                            <span>(Included)</span>
                          ) : typeof planDetail?.discount === 'number' ? (
                            Math.round(
                              planDetail?.discount.toString().replace('INR', '')
                            )
                          ) : typeof planDetail?.discount === 'string' ? (
                            Math.round(planDetail?.discount.replace('INR', ''))
                          ) : (
                            planDetail?.discount || '0'
                          )}
                        </td>
                      </tr>
                    )}
                    {planDetail?.NetPremium != false && (
                      <tr>
                        <th>Net Premium</th>

                        <td style={{ textAlign: 'end' }}>
                          {' '}
                          {planDetail?.NetPremium === true ? (
                            <span>(Included)</span>
                          ) : typeof planDetail?.NetPremium === 'number' ? (
                            Math.round(
                              planDetail?.NetPremium.toString().replace(
                                'INR',
                                ''
                              )
                            )
                          ) : typeof planDetail?.NetPremium === 'string' ? (
                            Math.round(
                              planDetail?.NetPremium.replace('INR', '')
                            )
                          ) : (
                            planDetail?.NetPremium || '0'
                          )}
                        </td>
                      </tr>
                    )}
                    {planDetail?.GST != false && (
                      <tr>
                        <th>GST (18%) </th>

                        <td style={{ textAlign: 'end' }}>
                          {' '}
                          {planDetail?.GST === true ? (
                            <span>(Included)</span>
                          ) : typeof planDetail?.GST === 'number' ? (
                            Math.round(
                              planDetail?.GST.toString().replace('INR', '')
                            )
                          ) : typeof planDetail?.GST === 'string' ? (
                            Math.round(planDetail?.GST.replace('INR', ''))
                          ) : (
                            planDetail?.GST
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className='col-12 px-0'>
                <div className='card mt-5'>
                  <div className='card-footer border-0 d-flex justify-content-between'>
                    <h2 className='mb-0 fs-3'>Total Payble</h2>
                    <p className='mb-0 fs-3'>
                      {' '}
                      {planDetail3?.FinalPremium === true ? (
                        <span>(Included)</span>
                      ) : typeof planDetail3?.FinalPremium === 'number' ? (
                        Math.round(
                          planDetail3?.FinalPremium.toString().replace(
                            'INR',
                            ''
                          )
                        )
                      ) : typeof planDetail3?.FinalPremium === 'string' ? (
                        Math.round(planDetail3?.FinalPremium.replace('INR', ''))
                      ) : (
                        planDetail3?.FinalPremium
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}

      <Modal
        isOpen={panModal}
        toggle={togglePanModal}
        size='md'
        className='modal-dialog-centered'
      >
        <ModalBody>
          <div className='modal-logo'>
            <a>
              <img src='/assets/images/ex_new_2.png'></img>
            </a>
          </div>
          <div className='modal_heading w-100 mb-3'>
            <h4 className='text-center fs-1 mb-4'>Upload your Pan Card</h4>

            <div onClick={togglePanModal} class='v2_close false'>
              <a>
                <img
                  src='/assets/images/delete_icon.svg'
                  title='Close'
                  alt='close'
                />
              </a>
            </div>
          </div>
          <div>
            <form onSubmit={submitPanDetails} className='login_form'>
              <label className='custom-field two'>
                <input
                  type='text'
                  maxLength={10}
                  className='form-control mb-3 text-uppercase'
                  onChange={e => {
                    handleChangePanValue(e.target.value)
                  }}
                  placeholder=''
                />
                <span className='placeholder'>Pan Card Number</span>

                <p className='f-error m-0 mb-5 fs-3'>{pan.error}</p>
              </label>

              <div className='img_upload'>
                <div className='upload-box'>
                  <div class='content'>
                    <div class='upload-icon'>
                      <img
                        class=''
                        src='https://res.cloudinary.com/www-santhoshthomas-xyz/image/upload/v1620293451/RapTor/folder_1_ipacc2.png'
                        alt=''
                      />
                    </div>

                    <h5 class='text'>Drag and Drop Your Files Here</h5>
                    <p>Or</p>

                    <div class='upload-btn-wrapper'>
                      <button type='button' className='btn'>
                        Upload
                      </button>
                      <input type='file' name='myfile' />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="d-flex justify-content-between align-items-center mt-5 fs-4">
                <input
                  type="file"
                  className="d-none"
                  style={{ cursor: "pointer" }}
                  id={"panImage"}
                  name={"panImage"}
                  onChange={(e) => handleUploadPanFile(e.target.files)}
                  accept={"image/*"}
                />

                <label htmlFor={"panImage"} className="">
                  <p className="fs-2  fw-bold mx-5">
                    Pan Card
                  </p>
                  <img
                    src={
                      pan.image ? pan.image : "/assetss/images/pancardicon.png"
                    }
                    className=" d-block"
                    style={{
                      objectFit: "contain",
                      height: "150px",
                      width: "150px",
                      cursor: "pointer",
                    }}
                  />
                </label>
              </div> */}

              <div className='d-flex gap-5 justify-content-center mt-4'>
                <div>
                  <button
                    type='submit'
                    className='btn'
                    disabled={
                      pan.number != '' && pan.image != '' ? '' : 'disabled'
                    }
                  >
                    Next
                  </button>
                </div>
                <button
                  type='button'
                  className='btn'
                  onClick={() => {
                    setPan({ number: '', error: '', image: '' })
                    togglePanModal()
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        // backdrop='static'
        isOpen={modalOpen2}
        toggle={() => setModalOpen2(!modalOpen2)}
        size='md'
        className='modal-dialog-centered'
      >
        <ModalBody>
          <div className='modal-logo'>
            <a>
              <img src={magmaImg}></img>
            </a>
          </div>
          <div
            onClick={() => setModalOpen2(!modalOpen2)}
            class='v2_close false'
          >
            <a>
              <img
                src='/assets/images/delete_icon.svg'
                title='Close'
                alt='close'
              />
            </a>
          </div>
          {!motorRequest?.isMotorRequestTrue &&
            apiRequestQQ?.NewPolicyType !== 'ODOnly' && (
              <>
                <div className='modal_heading w-100 mb-3'>
                  <h4 className='text-start fs-3 mb-4'>
                    {' '}
                    Do you want io include PA Owner Cover in this policy?
                  </h4>
                </div>
                {/* {apiRequestQQ.AddOns.PersonalAccident === true && (<div className="d-flex gap-5"> */}

                <div className='d-flex justify-content-start gap-5'>
                  <div className='box'>
                    <div class='item' onClick={() => magmaPaYesCall()}>
                      <div class='form-check'>
                        <input
                          type='checkbox'
                          checked={isPAChecked1}
                          onChange={handlePAChange1}
                          // id=""
                          // name="check"
                          className='form-check-input'
                        />
                        <label className='form-check-label'>Yes</label>
                      </div>
                    </div>
                  </div>
                  <div className='box'>
                    <div class='item' onClick={() => magmaPaNoCall()}>
                      <div class='form-check'>
                        <input
                          type='checkbox'
                          checked={isPAChecked2}
                          onChange={handlePAChange2}
                          // id=""
                          // name="check"
                          className='form-check-input'
                        />
                        <label className='form-check-label'>No</label>
                      </div>
                    </div>
                  </div>
                  {/* <Button className="btn" onClick={() => magmaPaYesCall()}>
              Yes
            </Button> */}
                  {/* <Button className="btn" onClick={() => magmaPaNoCall()}>
              No
            </Button> */}
                </div>
              </>
            )}

          {showBox && apiRequestQQ?.NewPolicyType !== 'ODOnly' && (
            <FormGroup check>
              <Label
                className='form-check-label fs-4'
                // style={{ background: "white", marginBottom: "10px" }}
                htmlFor='aacche12'
                style={{
                  borderBottom: '1px solid #ddd',
                  paddingBottom: '10px'
                }}
                check
              >
                <Input
                  type='checkbox'
                  name='checkbox1'
                  {...register('checkbox1')}
                  checked={checkboxes.checkbox1}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: '10px' }}
                  id='chec1'
                />{' '}
                <label htmlFor='chec1'>
                  Owner does not have valid driving license
                </label>
              </Label>
              <br />

              <Label
                className='form-check-label fs-4'
                // style={{ background: "white", marginBottom: "10px" }}
                htmlFor='aacche12'
                style={{
                  borderBottom: '1px solid #ddd',
                  paddingBottom: '10px'
                }}
                check
              >
                <Input
                  type='checkbox'
                  name='checkbox2'
                  {...register('checkbox2')}
                  checked={checkboxes.checkbox2}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: '10px' }}
                  id='chec2'
                />{' '}
                <label htmlFor='chec2'>
                  Driver having existing PA cover of Rs 15 lakhs
                </label>
              </Label>
              <br />

              <Label
                className='form-check-label fs-4'
                // style={{ background: "white", marginBottom: "10px" }}
                htmlFor='aacche12'
                style={{
                  borderBottom: '1px solid #ddd',
                  paddingBottom: '10px'
                }}
                check
              >
                <Input
                  type='checkbox'
                  name='checkbox3'
                  {...register('checkbox3')}
                  checked={checkboxes.checkbox3}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: '10px' }}
                  id='chec3'
                />{' '}
                <label htmlFor='chec3'>
                  Own multiple vehicles and have opted for PA to Owner Driver
                  Cover in the another vehicle insurance policy
                </label>
              </Label>
            </FormGroup>
          )}

          {apiRequestQQ.AddOns.ZeroDepriciationCover === true &&
            apiRequestQQ.NewPolicyType !== 'Bundled' && (
              <div>
                {' '}
                <div className='modal_heading w-100 mb-3 mt-4'>
                  <h4 className='text-start fs-3 mb-4'>
                    {' '}
                    Do you have Zero Depth in previous policy?
                  </h4>
                </div>
                <div className='d-flex justify-content-start gap-5 mb-4'>
                  <div className='box'>
                    <div class='item'>
                      <div class='form-check'>
                        <input
                          type='checkbox'
                          checked={isZeroDepChecked1}
                          onChange={handleZeroDepChange1}
                          id='checkbox-rect11'
                          // name="check"
                          className='form-check-input'
                        />
                        <label
                          htmlFor='checkbox-rect11'
                          className='form-check-label'
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='box'>
                    <div class='item'>
                      <div class='form-check'>
                        <input
                          type='checkbox'
                          checked={isZeroDepChecked2}
                          onChange={handleZeroDepChange2}
                          id='checkbox-rect12'
                          // name="check"
                          className='form-check-input'
                        />
                        <label
                          htmlFor='checkbox-rect12'
                          className='form-check-label'
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <Button className="btn" onClick={() => magmaPaYesCall()}>
              Yes
            </Button> */}
                  {/* <Button className="btn" onClick={() => magmaPaNoCall()}>
              No
            </Button> */}
                </div>
              </div>
            )}

          {apiRequestQQ.AddOns.InvoiceCover === true &&
            apiRequestQQ.NewPolicyType !== 'Bundled' && (
              <div>
                <div className='modal_heading w-100 mb-3'>
                  <h4 className='text-start fs-3 mb-4'>
                    {' '}
                    Do you have RTI in previous policy?
                  </h4>
                </div>
                <div className='d-flex justify-content-start gap-5 mb-4'>
                  <div className='box'>
                    <div class='item'>
                      <div class='form-check'>
                        <input
                          type='checkbox'
                          checked={isRTIChecked1}
                          onChange={handleRTIChange1}
                          id='checkbox-rect21'
                          // name="check"
                          className='form-check-input'
                        />
                        <label
                          htmlFor='checkbox-rect21'
                          className='form-check-label'
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='box'>
                    <div class='item'>
                      <div class='form-check'>
                        <input
                          type='checkbox'
                          id='checkbox-rect22'
                          // name="check"
                          checked={isRTIChecked2}
                          onChange={handleRTIChange2}
                          className='form-check-input'
                        />
                        <label
                          htmlFor='checkbox-rect22'
                          className='form-check-label'
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <Button className="btn" onClick={() => magmaPaNoCall()}>
              No
            </Button> */}
                </div>
              </div>
            )}
          {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
          <button
            disabled={hidebtn}
            style={{ filter: hidebtn ? 'blur(2px)' : 'none' }}
            onClick={() => magmaSubmitCall()}
            type='button'
            className='btn btn-primary fs-4 px-2 py-3 mt-6 d-flex justify-content-center'
          >
            {spinner ? (
              <div class='spinner-border text-light' role='status'>
                <span class='sr-only'></span>
              </div>
            ) : (
              'Submit'
            )}
          </button>
        </ModalBody>
      </Modal>

      {shareQuoteLinkModal && (
        <SharePaymentQuotationModal
          sharePaymentModal={shareQuoteLinkModal}
          toggleSharePaymentModal={toggleShareQuoteLinkModal}
          quotationPage={true}
        />
      )}

      {/* } */}

      {ApiErrorArr && ApiErrorArr.length > 0 && (
        <div className='row'>
          <div className='col-12'>
            <div className='quote_follower'>
              <p>
                We did not get a quote form the following insurers
                <br />
                Might be possible reason at insurance company's end like MMV
                decline or RTO restricted or some technical issue.
                <br />
              </p>
              <ul
                className='insurer_list'
                style={{ display: 'flex', justifyContent: 'space-evenly' }}
              >
                {ApiErrorArr.map((item, key) => (
                  <li className='insurer_img'>
                    <a href='#!'>
                      <img
                        style={{ height: '50px' }}
                        key={key}
                        src={item.image}
                      />
                    </a>
                    {/* <div style={{ marginTop: "20px", fontSize: "15px" }}>
                      Error Message: {item.error}
                    </div> */}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  )
}

export default Quotelist
// prevNCB
// function showdown() {
//   // document.getElementById("idvmeasure").classList.remove("idvcustomshow");
//   document.getElementById("addcover").classList.remove("idvcustomshow");
//   document.getElementById("addon").classList.remove("idvcustomshow");
//   document.getElementById("idvmeasure").classList.toggle("idvcustomshow");
//   document.getElementById("prevNCB").classList.remove("idvcustomshow");
// }

// function showdown1() {
//   // document.getElementById("idvmeasure").classList.remove("idvcustomshow");
//   // document.getElementById("addcover").classList.remove("idvcustomshow");
//   // document.getElementById("addon").classList.remove("idvcustomshow");
//   // document.getElementById("prevNCB").classList.remove("idvcustomshow");
//   document.getElementById("addcover").classList.toggle("idvcustomshow");
// }

// function showdown2() {
//   // document.getElementById("idvmeasure").classList.remove("idvcustomshow");
//   // document.getElementById("addcover").classList.remove("idvcustomshow");
//   // document.getElementById("addon").classList.remove("idvcustomshow");
//   // document.getElementById("prevNCB").classList.remove("idvcustomshow");
//   document.getElementById("addon").classList.toggle("idvcustomshow");
// }

// function showdown3() {
//   document.getElementById("idvmeasure").classList.remove("idvcustomshow");
//   document.getElementById("addcover").classList.remove("idvcustomshow");
//   // document.getElementById("addon").classList.remove("idvcustomshow");
//   document.getElementById("addon").classList.remove("idvcustomshow");
//   document.getElementById("prevNCB").classList.toggle("idvcustomshow");
// }
